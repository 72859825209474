import { Component } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import Base64 from 'crypto-js/enc-base64';
import sha512 from 'crypto-js/sha512';
import publicIp from 'public-ip';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import packageInfo from '../../../package.json';
import { AppComponent } from '../app.component';
import { btnStyle, mtsLoginLogo } from '../config/app.config';
import { translate } from '../main/http-service';
import { Body } from '../main/modal/body.handler';
import { ButtonType } from '../main/modal/button.handler';
import { InitButton } from '../main/modal/modal.button';
import { ModalHandler } from '../main/modal/modal.handler';
import { translateModal } from '../main/modal/modal.translate';

@Component({
   selector: 'app-login',
   templateUrl: './login.component.html',
   styleUrls: ['./login.component.css'],
})
export class LoginComponent {
   btn = btnStyle;

   invalidLogin: boolean;

   tooManyLoginHandler: ModalHandler = new ModalHandler();

   underMaintenanceHandler: ModalHandler = new ModalHandler();

   mtsLogo = mtsLoginLogo;

   login = new UntypedFormGroup({
      username: new UntypedFormControl('', Validators.required),
      'current-password': new UntypedFormControl('', Validators.required),
   });

   get username(): AbstractControl {
      return this.login.get('username');
   }

   get password(): AbstractControl {
      return this.login.get('current-password');
   }

   constructor() {
      this.tooManyLoginHandler.title = 'Your user has an active login from another device. By clicking OK, you will force the login and the other session will be closed. Do you want to continue?';
      this.tooManyLoginHandler.ignoreBackdropClick = true;
      this.tooManyLoginHandler.closeButton = false;
      [this.tooManyLoginHandler.buttons] = InitButton(
         { type: ButtonType.OkCancel, prefix: 'confirm' },
         1,
      );

      this.underMaintenanceHandler.title = 'The system is currently under maintenance. Please try again later. Thank you for your understanding.';
      this.underMaintenanceHandler.ignoreBackdropClick = true;
      this.underMaintenanceHandler.closeButton = true;
      [this.underMaintenanceHandler.buttons] = InitButton(
         { type: ButtonType.Ok, prefix: 'confirm' },
         1,
      );
   }

   initValue(): void {
      this.username.reset();
      this.password.reset();
   }

   async loginSubmit(forceLogin?: boolean): Promise<void> {
      const timeZoneOffset = new Date().getTimezoneOffset();

      const fp = await FingerprintJS.load();
      const result = await fp.get();

      AppComponent.loginEvent.next({
         userId: this.username.value,
         password: Base64.stringify(sha512(this.password.value)),
         version: packageInfo.version,
         deviceId: result.visitorId,
         termName: result.components.platform.value,
         timeZoneOffset,
         forceLogin,
      });
      AppComponent.invalidLoginObservable.subscribe(() => {
         this.invalidLogin = true;
      });

      AppComponent.tooManyLoginObservable.subscribe(() => {
         this.tooManyLoginHandler.change.next(translateModal(this.tooManyLoginHandler, translate));
         this.tooManyLoginHandler.event.next();
      });

      AppComponent.underMaintenanceObservable.subscribe(() => {
         this.underMaintenanceHandler.change.next(translateModal(this.underMaintenanceHandler, translate));
         this.underMaintenanceHandler.event.next();
      });
   }

   onForceLogin(event: Body): void {
      if (event.getKey() === 'confirm-ok') {
         this.loginSubmit(true);
      }
   }
}
