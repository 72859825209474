import { Component, OnInit } from '@angular/core';
import { HttpService, isResultValid } from 'src/app/main/http-service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { mtsLoginLogo } from '../config/app.config';
import { routes } from '../config/routes.config';

@Component({
   selector: 'app-email-unsubsribe',
   templateUrl: './email-unsubsribe.component.html',
   styleUrls: ['./email-unsubsribe.component.css'],
})
export class EmailUnsubsribeComponent implements OnInit {
   unsubSucces: boolean;

   unsubFail: boolean;

   service: HttpService;

   mtsLogo = mtsLoginLogo;

   constructor(private http: HttpClient, private route: ActivatedRoute) {
      this.service = new HttpService(this.http, routes.emailUnsubscribe);
   }

   ngOnInit(): void {
      this.route.params.subscribe((paramMap) => {
         if (paramMap.key && paramMap.type) {
            this.unsubscribe(paramMap.key, paramMap.type);
         }
      });
   }

   unsubscribe(key: string, type: string): void {
      this.service.delete('', { key, type }, (res) => {
         if (isResultValid(res)) {
            this.unsubFail = false;
            this.unsubSucces = true;
         } else {
            this.unsubFail = true;
            this.unsubSucces = false;
         }
      });
   }
}
