<form
   class="col-xd-12 col-sm-9 col-md-6 col-lg-3 center"
   (ngSubmit)="loginSubmit()"
   [formGroup]="login"
>
   <div>
      <img [src]="mtsLogo" alt="MedTracS" style="max-width: 100%" />
   </div>
   <br />
   <div *ngIf="invalidLogin" class="alert alert-danger">{{
      'Wrong username or password!' | translate
   }}</div>

   <input
      formControlName="username"
      id="username"
      type="text"
      class="form-control"
      placeholder="{{ 'Username' | translate }}"
      autocomplete="username"
      autofocus
   />
   <div *ngIf="username.touched && username.invalid" class="alert alert-danger">
      {{ 'Please fill in username.' | translate }}</div
   >

   <input
      formControlName="current-password"
      id="current-password"
      type="password"
      class="form-control"
      placeholder="{{ 'Password' | translate }}"
      autocomplete="current-password"
   />
   <div *ngIf="password.touched && !password.valid" class="alert alert-danger">
      {{ 'Please fill in password.' | translate }}</div
   >

   <button class="btn btn-block" [ngClass]="btn" type="submit" [disabled]="!login.valid">{{
      'Login' | translate
   }}</button>
</form>
<lib-modal [handler]="tooManyLoginHandler" (button)="onForceLogin($event)"></lib-modal>

<lib-modal [handler]="underMaintenanceHandler"></lib-modal>