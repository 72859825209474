<lib-table
   [label]="'Records without status' | translate"
   [headers]="headers"
   [readOnly]="true"
   [readOnlyFilter]="'inQueue'"
   [codes]="codes"
   [rows]="records"
   [headerButtons]="[resendSelectedButtonText]"
   (headerEvent)="onButtonClick($event)"
   (checkEvent)="onCheckClick($event)"
>
</lib-table>
<lib-modal [handler]="confirmHandler" (button)="onResendConfirmed($event)"></lib-modal>
