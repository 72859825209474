import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
   faComment as farComment,
   faCommentDots as farCommentDots,
   faComments as farComments,
   faEdit as farEdit,
   faFileAlt,
   faEnvelope,
   faClone,
} from '@fortawesome/free-regular-svg-icons';
import {
   faBox,
   faCheck,
   faChevronDown,
   faChevronUp,
   faClipboardCheck,
   faClipboardList,
   faCogs,
   faComment,
   faCommentDots,
   faComments,
   faDollarSign,
   faDolly,
   faDownload,
   faEdit,
   faGlobeAmericas,
   faHome,
   faInfo,
   faInfoCircle,
   faKey,
   faPlus,
   faPrint,
   faReceipt,
   faReplyAll,
   faSearch,
   faShare,
   faSignOutAlt,
   faThumbtack,
   faTimes,
   faTrashAlt,
   faTruck,
   faUndoAlt,
   faUserEdit,
   faUsersCog,
   faWarehouse,
   faSyncAlt,
   faUserShield,
   faBook,
   faList,
   faUsers,
   faExclamationTriangle,
   faArrowUp,
   faArrowDown,
} from '@fortawesome/free-solid-svg-icons';

@Component({
   selector: 'lib-icon',
   templateUrl: './icon.component.html',
   styleUrls: ['./icon.component.css'],
})
export class IconComponent {
   @Input() content: string;

   @Input() libTooltip: string;

   @Output() iClicked = new EventEmitter();

   fas = {
      faPlus,
      faCheck,
      faEdit,
      faTimes,
      faDownload,
      faComment,
      faComments,
      faCommentDots,
      faDollarSign,
      faSignOutAlt,
      faKey,
      faTrashAlt,
      faClipboardCheck,
      faClipboardList,
      faDolly,
      faThumbtack,
      faTruck,
      faCogs,
      faBox,
      faInfo,
      faInfoCircle,
      faPrint,
      faGlobeAmericas,
      faHome,
      faUsersCog,
      faWarehouse,
      faSearch,
      faChevronDown,
      faChevronUp,
      faUserEdit,
      faUndoAlt,
      faReceipt,
      faShare,
      faReplyAll,
      faSyncAlt,
      faUserShield,
      faBook,
      faList,
      faUsers,
      faExclamationTriangle,
      faArrowUp,
      faArrowDown,
   };

   far = { farEdit, farComment, farComments, farCommentDots, faFileAlt, faEnvelope, faClone };

   onClick(): void {
      this.iClicked.emit();
   }
}
