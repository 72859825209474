import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { routes } from 'src/app/config/routes.config';
import { Icons } from 'src/app/handlers/icon.handler';
import { HttpService, isResultValid, translate } from 'src/app/main/http-service';
import { Body } from 'src/app/main/modal/body.handler';
import { ButtonType } from 'src/app/main/modal/button.handler';
import { InitButton } from 'src/app/main/modal/modal.button';
import { ModalHandler } from 'src/app/main/modal/modal.handler';
import { translateModal } from 'src/app/main/modal/modal.translate';
import { Codes, Headers, center } from 'src/app/main/table/table.handler';
import { IButtonEvent, ICheckEvent } from 'src/app/main/table/table.interface';
import { IconClass } from '../../../main/icon/icon.handler';
import { translateHeaders } from '../../../main/table/table.translate';
import { IResendRecords } from '../../../medtracs-entities/sysadmin/maintenance/resend-records/resend-records.interface';

@Component({
   selector: 'resend-records',
   templateUrl: './resend-records.component.html',
   styleUrls: ['./resend-records.component.css'],
})
export class ResendRecordsComponent implements OnInit {
   service: HttpService;

   icons = Icons;

   headers = new Headers(['Select', 'Company', 'Division', 'Division ID', center('Operation'), 'Record ID', 'Product code', 'Serial number', 'Create date', 'Username']);

   codes: Codes = new Codes(['selected', 'compName', 'divName', 'divId', center('op'), 'recId', 'prdCode', 'serialNr', 'createDate', 'userId']);

   resendSelectedButtonText = translate('Resend selected records');

   records: IResendRecords[] = [];

   confirmHandler: ModalHandler = new ModalHandler();

   listToResend: {recId: number, divId: number}[] = [];

   constructor(public route: ActivatedRoute,private http: HttpClient) {
      this.service = new HttpService(this.http, routes.sysadmin.maintenances.resendRecords);
   }

   ngOnInit(): void {
      translateHeaders(this.headers, translate);
      this.getRecordsWithoutStatus();
   }

   getRecordsWithoutStatus(): void {
      this.service.get<{records: IResendRecords[], count: number}>({}, (result) => {
         if (result) {
            if (isResultValid(result)) {
               this.records = result.body.records;
               this.records.forEach((record) => {
                  record.selected = false;
                  record.op = new IconClass(
                     // eslint-disable-next-line no-nested-ternary
                     record.op === 'IN' ? Icons.check : record.op === 'OUT' ? Icons.receipt : Icons.undo,
                     'operation',
                     [],
                     translate(
                        // eslint-disable-next-line no-nested-ternary
                        record.op === 'IN'
                           ? 'Verification'
                           : record.op === 'OUT'
                           ? 'Decommission'
                           : 'Reversal',
                     ),
                  ) as any;
               });
            }
         }
      });
   }

   onButtonClick(event: IButtonEvent<IResendRecords>): void {
      this.onResend(event);
   }

   onResend(event: IButtonEvent<IResendRecords>): void {
      this.confirmHandler.title = "Are you want to resend the selected records?";
      [this.confirmHandler.buttons] = InitButton(
         { type: ButtonType.OkCancel, prefix: 'confirm' },
         1,
      );
      this.confirmHandler.change.next(
         translateModal(this.confirmHandler, translate),
      );
      this.confirmHandler.event.next();
      
   }

   onResendConfirmed(event: Body): void {
      if (event.getKey() === 'confirm-ok') {
         this.service.post({records: this.listToResend}, (result) => {
            if(result) {
               if(isResultValid(result)) {
                  this.getRecordsWithoutStatus();
               }
            }
         });
      }
   }

   onCheckClick(event: ICheckEvent<IResendRecords>): void {
      if(event.row.selected) {
         this.listToResend.push({recId: event.row.recId, divId: event.row.divId});
      } else {
         const index = this.listToResend.findIndex((record) => record.recId === event.row.recId);
         this.listToResend.splice(index, 1);
      }
   }
}
