import { Params } from '@angular/router';
import { Locales } from '../../modal/locale.module';
import { Redirect } from '../redirect/redirect';
import { HttpService } from '../service/service';
import { localeType, TranslatePipe } from './translate.pipe';

interface IAppComponent {
   session: { acceptLanguages: string };
}

interface IThisComponent {
   redirect: Redirect;
   translateService: HttpService;
}

export class TranslateHandler {
   private static localeParam: boolean;

   public static construct(allowedLocaleKeys: Array<localeType>): void {
      this.setLocale(
         this.getLocale() || TranslatePipe.getLocaleKey(TranslatePipe.getDefaultLocale()),
      );
      TranslatePipe.setAllowedLocaleKeys(allowedLocaleKeys);
      TranslatePipe.setLocale(Locales[this.getLocale()] || TranslatePipe.getDefaultLocale());
   }

   public static queryParamMap(queryParamMap: Params): void {
      this.localeParam = false;
      if (Object.keys(Locales).includes(queryParamMap.get('locale'))) {
         this.localeParam = true;
         this.setLocale(queryParamMap.get('locale') as any);
         TranslatePipe.setLocale(Locales[this.getLocale()]);
      }
   }

   public static async startSession<T extends IAppComponent, U extends IThisComponent>(
      AppComponent: T,
      thisComponent: U,
      loginPage: boolean,
      callback: () => void,
   ): Promise<void> {
      AppComponent.session.acceptLanguages.split(',').some((accept) => {
         const locale = accept.split(';')[0].split('-')[0];
         if (Object.keys(Locales).includes(locale) && !this.localeParam) {
            if (this.getLocale() !== locale && !this.getLockedLocale()) {
               TranslatePipe.setLocale(Locales[locale]);
            }
            return true;
         }
         return false;
      });
      const process = async () => {
         const localeKey = TranslatePipe.getLocaleKey(TranslatePipe.getLocale());
         if (this.getLocale() !== localeKey) {
            this.setLocale(localeKey);
            await thisComponent.redirect.reloadPage(callback);
         } else {
            callback();
         }
      };
      if (TranslatePipe.getLocale() !== TranslatePipe.getDefaultLocale()) {
         TranslatePipe.getService(thisComponent.translateService, loginPage, process);
      } else {
         TranslatePipe.setTranslations({});
         await process();
      }
   }

   public static setLockedLocale(): void {
      localStorage.setItem('locked-locale', String(true));
   }

   public static getLockedLocale(): boolean {
      return Boolean(localStorage.getItem('locked-locale'));
   }

   private static getLocale(): localeType {
      return localStorage.getItem('locale') as localeType;
   }

   private static setLocale(locale: localeType) {
      if (!locale) {
         localStorage.removeItem('locale');
      } else if (TranslatePipe.isAllowedLocale(Locales[locale])) {
         localStorage.setItem('locale', locale);
      }
   }
}
