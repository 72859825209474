<div class="center">
   <div align="center">
      <img [src]="mtsLogo" alt="MedTracS" style="max-width: 50%" />
   </div>
   <br /><br />
   <div *ngIf="unsubSucces" align="center" style="color: green">
      <h1>{{ 'Email unsubscribe is successful.' | translate }}</h1>
   </div>
   <div *ngIf="unsubFail" align="center" style="color: red">
      <h1>{{ 'Email unsubscribe is not successful!' | translate }}</h1>
   </div>
</div>
