/* eslint-disable import/no-extraneous-dependencies */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { IconComponent } from './icon.component';

@NgModule({
   declarations: [IconComponent],
   imports: [FormsModule, CommonModule, FontAwesomeModule, TooltipModule],
   exports: [IconComponent],
})
export class IconModule {}
