import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService, isResultValid } from 'src/app/main/http-service';
import { routes } from '../config/routes.config';
import { Icons } from '../handlers/icon.handler';
import { IconClass } from '../main/icon/icon.handler';
import { Codes, Headers, addIcon, center } from '../main/table/table.handler';
import { IIconEvent } from '../main/table/table.interface';
import { IAppRelease, IPortalRelease } from '../medtracs-entities/releases/release-entity';

@Component({
   selector: 'app-download-release',
   templateUrl: './download-release.component.html',
   styleUrls: ['./download-release.component.css'],
})
export class DownloadReleaseComponent implements OnInit {

   private service: HttpService;

   appReleases: IAppRelease[] = [];

   portalReleases: IPortalRelease[] = [];

   icons = Icons;

   hideAppCardBody = false;
   
   hidePortalCardBody = false;

   appHeaders = new Headers([
      'Version',
      center('Android 32 bit (for older devices)'),
      center('Android 64 bit(for newer devices) '),
      center('Windows 32 bit'),
      center('Windows 64 bit'),
      center('Windows 32 bit .zip'),
      center('Windows 64 bit .zip'),
      center('Application manual'),
      center('Validation documentation'),
   ]);

   appCodes = new Codes([
      'version',
      center('armeabi'),
      center('arm64'),
      center('win32'),
      center('win64'),
      center('win32-zip'),
      center('win64-zip'),
      center('app'),
      center('validation'),
   ]);

   portalHeaders = new Headers([
      'Version',
      center('Portal manual'),
      center('Validation documentation'),
   ]);

   portalCodes = new Codes([
      'version',
      center('portal'),
      center('validation'),
   ]);

   constructor(private http: HttpClient, public route: ActivatedRoute) {
      this.service = new HttpService(this.http, routes.docs);
   }

   ngOnInit(): void {
      this.get();
   }


   get(): void {
      this.service.post({}, (res: HttpResponse<{ appReleases: IAppRelease[], portalReleases: IPortalRelease[] }>) => {
         if (isResultValid(res)) {
            this.appReleases = res.body.appReleases;
            this.portalReleases = res.body.portalReleases;
            addIcon(
               this.appReleases,
               'armeabi',
               new IconClass(Icons.download, 'armeabi', [], 'Download'),
               ['armeabi'],
            );
            addIcon(
               this.appReleases,
               'arm64',
               new IconClass(Icons.download, 'arm64', [], 'Download'),
               ['arm64'],
            );
            addIcon(
               this.appReleases,
               'win32',
               new IconClass(Icons.download, 'win32', [], 'Download'),
               ['win32'],
            );
            addIcon(
               this.appReleases,
               'win64',
               new IconClass(Icons.download, 'win64', [], 'Download'),
               ['win64'],
            );
            addIcon(
               this.appReleases,
               'win32-zip',
               new IconClass(Icons.download, 'win32-zip', [], 'Download'),
               ['win32-zip'],
            );
            addIcon(
               this.appReleases,
               'win64-zip',
               new IconClass(Icons.download, 'win64-zip', [], 'Download'),
               ['win64-zip'],
            );
            addIcon(
               this.portalReleases,
               'portal',
               new IconClass(Icons.download, 'portal', [], 'Download'),
               ['portal'],
            );
            addIcon(
               this.appReleases,
               'app',
               new IconClass(Icons.download, 'app', [], 'Download'),
               ['app'],
            );
            addIcon(
               this.appReleases,
               'validation',
               new IconClass(Icons.download, 'validation', [], 'Download'),
               ['validation'],
            );
         }
      });
   }

   onIconClick(event: IIconEvent<IAppRelease>): void {
      this.service.download({ code: event.id, version: event.row.version }, () => null);
   }

   changeHideAppReleases(): void {
      this.hideAppCardBody = !this.hideAppCardBody;
   }

   changeHidePortalReleases(): void {
      this.hidePortalCardBody = !this.hidePortalCardBody;
   }

}
