import { AppComponent } from '../app.component';
import { ICheckEvent } from '../main/table/table.interface';
import { IGetUserRights } from '../medtracs-entities/admin/maintenance/user-rights/get.user.rights.entity';
import { IRights } from '../medtracs-entities/session/static.session.get.entity';

export function changeRight(right: keyof IRights, event: ICheckEvent<any>): void {
   if (event.row.USER_ID === AppComponent.session.user.userId) {
      if (event.row[event.elem] && !AppComponent.isRight(right)) {
         AppComponent.session.rights[right] = true;
      } else if (!event.row[event.elem] && AppComponent.isRight(right)) {
         AppComponent.session.rights[right] = false;
      }
      AppComponent.divisionChangeEvent.next(0);
   }
}

export function changeRightV2(
   right: keyof Omit<IRights, 'divId' | 'divName'>,
   userRight: IGetUserRights,
   fieldName: string,
): void {
   if (userRight.userId === AppComponent.session.user.userId) {
      const index = AppComponent.session.rights.findIndex((e) => e.divId === AppComponent.session.user.lastDiv);
      if (index === -1) return;
      if (userRight[fieldName] && !AppComponent.isRight(right)) {
         (AppComponent.session.rights[index])[right] = true;
      } else if (!userRight[userRight[fieldName]] && AppComponent.isRight(right)) {
         (AppComponent.session.rights[index])[right] = false;
      }
      AppComponent.divisionChangeEvent.next(0);
   }
}
