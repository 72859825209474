import { translate } from 'src/app/main/http-service';
import { ControlType } from 'src/app/main/modal/body.handler';
import { ButtonType } from 'src/app/main/modal/button.handler';
import { InitButton } from 'src/app/main/modal/modal.button';
import { ModalHandler } from 'src/app/main/modal/modal.handler';
import { AppComponent } from '../app.component';

export function documentHandler(): ModalHandler {
   const options = [
      { _id: 'app', value: translate('Application manual') },
      { _id: 'portal', value: translate('Portal manual') },
      { _id: 'validation', value: translate('Validation documentation') },
   ];
   if (
      AppComponent.session &&
      AppComponent.session.user &&
      AppComponent.session.user.lang &&
      (AppComponent.session.user.lang === 'HUN' || AppComponent.session.user.lang === 'RON')
   ) {
      options.push({ _id: 'aszf', value: translate('Terms and Conditions') });
   }
   const handler: ModalHandler = new ModalHandler();
   handler.title = 'Download documents';
   handler.body = [
      {
         id: 'docs-options',
         field: 'value',
         label: 'Type:',
         type: ControlType.select,
         default: 'Choose',
         required: true,
         placeHolder: 'Type',
         options,
      },
   ];
   [handler.buttons] = InitButton({ type: ButtonType.OkCancel, prefix: 'download' }, 1);
   return handler;
}
