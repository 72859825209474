import { ButtonType } from '../../modal/button.handler';
import { InitButton } from '../../modal/modal.button';
import { ModalHandler } from '../../modal/modal.handler';

export function broadcastHandler(): ModalHandler {
   const handler: ModalHandler = new ModalHandler();
   handler.title = 'Attention!';
   [handler.buttons] = InitButton({ type: ButtonType.Ok, prefix: 'broadcast' }, 1);
   handler.keyboard = false;
   handler.ignoreBackdropClick = true;
   handler.closeButton = false;
   handler.classes = ['modal-lg', 'modal-dialog-centered'];
   return handler;
}
