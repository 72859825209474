/* eslint-disable no-underscore-dangle */
import { translate } from 'src/app/main/http-service';
import { ControlType } from 'src/app/main/modal/body.handler';
import { ButtonType } from 'src/app/main/modal/button.handler';
import { InitButton } from 'src/app/main/modal/modal.button';
import { ModalHandler } from 'src/app/main/modal/modal.handler';

import { getCsvMenus } from '../config/csv.options.config';
import { translateMenus } from '../main/table/table.translate';

export function generateListsHandler(currentUrl?: string): ModalHandler {
   const options = getCsvMenus();
   const handler: ModalHandler = new ModalHandler();
   handler.title = 'Generate lists';
   handler.body = [
      {
         id: 'csv-options',
         field: 'menu',
         label: 'Menu:',
         type: ControlType.select,
         default: 'Choose',
         required: true,
         placeHolder: 'Menu',
         disabled: true,
         options: translateMenus(
            options.map((csvOption) => ({ ...csvOption })),
            translate,
         ),
      },
      {
         id: 'rows',
         type: ControlType.checkbox,
         value: true,
         placeHolder: 'Number of rows: max 10000',
         disabled: true,
      },
   ];
   if (currentUrl) {
      const found = options.find((option) => currentUrl.startsWith(option._id));
      const dropdown = handler.body.find((body) => body.id === 'csv-options');
      dropdown.value = found ? found._id : undefined;
   }
   [handler.buttons] = InitButton({ type: ButtonType.OkCancel, prefix: 'generate-lists' }, 1);
   handler.ignoreBackdropClick = true;
   return handler;
}
