/* eslint-disable no-restricted-globals */
/* eslint-disable no-useless-constructor */
import { Directive, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';

@Directive({
   selector: '[number]',
})
export class NumberDirective {
   constructor(private el: ElementRef, private cd: ChangeDetectorRef) {}

   @HostListener('input', ['$event'])
   onInput(event: InputEvent): void {
      const el = this.el.nativeElement as HTMLInputElement;
      const orig = el.value;
      el.value = Array.from(el.value)
         .filter((char) => !isNaN(Number(char)) && char.trim().length > 0)
         .join('');
      if (el.value !== orig) {
         el.dispatchEvent(new Event('input'));
      }
   }
}
