<lib-modal [handler]="timeoutHandler" (button)="onTimeout()"></lib-modal>
<lib-modal [handler]="unauthorizedHandler" (button)="onUnauthorizedClick($event)"></lib-modal>
<lib-modal [handler]="internalErrorHandler"></lib-modal>
<lib-modal [handler]="errorHandler"></lib-modal>
<lib-modal [handler]="reloadHandler"></lib-modal>
<lib-modal [handler]="broadcastHandler" (button)="onBroadcastButton()"></lib-modal>
<lib-modal [handler]="ipErrorHandler"></lib-modal>

<lib-modal [handler]="chooseLanguageHandler" (button)="onChooseLanguage($event)"></lib-modal>
<lib-modal [handler]="generateListsHandler" (button)="onGenerateLists($event)"></lib-modal>

<ngx-spinner size="medium" bdColor="rgba(50,50,50,0.15)" [color]="spinnerColor" type="ball-clip-rotate-multiple">
</ngx-spinner>

<lib-navbar *ngIf="navbarHandler" [handler]="navbarHandler.navbarHandler" (button)="onButton($event)">
</lib-navbar>

<router-outlet></router-outlet>
<div class="footer">
   <p *ngIf="!environment.production" style="float: left; color: red; margin-right: 1rem">
      {{ '!! TEST !!' }}
   </p>
   <p style="float: left">
      {{ app.session && app.session.user ? app.session.user.userId + ' - ' + division : '' }}
   </p>
   <p style="float: right">
      © 2018-{{ year }} MedTracS -
      {{ 'Szeged Software Inc., Pharmalog S.R.L. & Optima.Systems Co. Ltd.' | translate }}
   </p>
   <div> </div>
</div>