/* eslint-disable import/no-extraneous-dependencies */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { enGbLocale, huLocale, plLocale, roLocale } from 'ngx-bootstrap/locale';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AlertModule } from './alert/alert.module';
import { DescriptionComponent } from './description/description.component';
import { TranslatePipe } from './http-service';
import { IconModule } from './icon/icon.module';
import { LocaleModule } from './modal/locale.module';
import { ModalModule } from './modal/modal.module';
import { NavbarModule } from './navbar/navbar.module';
import { NumberDirective } from './number.directive';
import { SelectModule } from './select/select.module';
import { TableModule } from './table/table.module';
import { UserSelectModule } from './user-select/user-select.module';

defineLocale('hu', huLocale);
defineLocale('hu-hu', huLocale);
defineLocale('en', enGbLocale);
defineLocale('en-gb', enGbLocale);
defineLocale('pl', plLocale);
defineLocale('pl-pl', plLocale);
defineLocale('ro', roLocale);
defineLocale('ro-ro', roLocale);

@NgModule({
   declarations: [TranslatePipe, NumberDirective, DescriptionComponent],
   imports: [
      CommonModule,
      NavbarModule,
      IconModule,
      AlertModule,
      TableModule,
      FormsModule,
      ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
      BsDatepickerModule.forRoot(),
      LocaleModule,
      UserSelectModule,
      TooltipModule,
   ],
   exports: [
      TranslatePipe,
      NumberDirective,
      CommonModule,
      NavbarModule,
      IconModule,
      AlertModule,
      TableModule,
      SelectModule,
      ModalModule,
      FormsModule,
      ReactiveFormsModule,
      BsDatepickerModule,
      LocaleModule,
      DescriptionComponent,
      UserSelectModule,
   ],
})
export class MainModule {}
