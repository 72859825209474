import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
   selector: 'app-display-json',
   templateUrl: './display-json.component.html',
   styleUrls: ['./display-json.component.css'],
})
export class DisplayJsonComponent implements OnInit {
   @Input() jsonValue: any;

   @Input() title?: string;

   // eslint-disable-next-line no-useless-constructor
   constructor(public modalRef: BsModalRef) {}

   ngOnInit(): void {
      this.jsonValue = JSON.stringify(JSON.parse(this.jsonValue), undefined, 4);
   }
}
