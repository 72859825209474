/* eslint-disable import/no-extraneous-dependencies */
import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { isIcons } from '../../icon/icon.handler';
import { IDropdown } from '../navbar.handler';

@Component({
   selector: 'lib-submenu',
   templateUrl: './submenu.component.html',
   styleUrls: ['./submenu.component.css'],
})
export class SubmenuComponent {
   @ViewChildren(BsDropdownDirective) private dropdowns: QueryList<BsDropdownDirective>;

   @Input() parent: IDropdown;

   @Output() button = new EventEmitter<string>();

   isIcons = isIcons;

   onMouseOver(element: BsDropdownDirective): void {
      this.dropdowns.forEach((item) => {
         if (item !== element && item.placement === 'right') {
            item.hide();
         } else if (item === element) {
            item.show();
         }
      });
   }

   onButton(id: string): void {
      this.button.emit(id);
   }
}
