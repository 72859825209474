<form [formGroup]="changePw">
   <div class="card border-dark">
      <div class="card-header" (click)="hideCardBodyPw = !hideCardBodyPw">
         <form class="form-inline">
            <b>{{ 'Modify user password' | translate }}</b>
            <div class="col" style="text-align: right">
               <lib-icon *ngIf="hideCardBodyPw" [content]="icons.chevronDown"></lib-icon>
               <lib-icon *ngIf="!hideCardBodyPw" [content]="icons.chevronUp"></lib-icon>
            </div>
         </form>
      </div>
      <div *ngIf="!hideCardBodyPw" class="card-body">
         <lib-alert [data]="clientInvalidData"></lib-alert>
         <div *ngIf="passwordChanged && !changePw.touched" class="alert alert-success">
            {{ 'Password changed successfully!' | translate }}
         </div>
         <div class="form-row">
            <div class="col-md-4 md-form form-group">
               <label for="current-password">{{ 'Current password' | translate }}</label>
               <input
                  id="current-password"
                  class="form-control"
                  type="password"
                  formControlName="current-password"
               />
            </div>
            <div class="col-md-4 md-form form-group">
               <label for="pw1">{{ 'New password' | translate }}</label>
               <input
                  id="pw1"
                  class="form-control"
                  type="password"
                  formControlName="pw1"
                  pattern="^(?=.*[\w])(?=.*[\W])[\w\W]{8,}$"
               />
            </div>
            <div class="col-md-4 md-form form-group">
               <label for="pw2">{{ 'New password again' | translate }}</label>
               <input
                  id="pw2"
                  class="form-control"
                  type="password"
                  formControlName="pw2"
                  pattern="^(?=.*[\w])(?=.*[\W])[\w\W]{8,}$"
               />
            </div>
            <div class="col-md-12 md-form form-inline">
               <button
                  type="submit"
                  class="btn btn-primary"
                  (click)="onSubmit()"
                  [disabled]="!changePw.valid"
               >
                  {{ 'Submit' | translate }}
               </button>
            </div>
         </div>
         <div
            class="alert alert-info"
            style="margin-top: 1rem; margin-bottom: 0"
            *ngIf="changePw.touched"
         >
            {{
               'Guideline: at least 8 characters, 1 special character (of "!@#$%^&"), 1 number, 1 uppercase and 1 lowercase letter of the English alphabet.'
                  | translate
            }}
         </div>
      </div>
   </div>
</form>

<form [formGroup]="emailSettings">
   <div class="card border-dark">
      <div class="card-header" (click)="changeHideEmail()">
         <form class="form-inline">
            <b>{{ 'Email settings' | translate }}</b>
            <div class="col" style="text-align: right">
               <lib-icon *ngIf="hideCardBodyEmail" [content]="icons.chevronDown"></lib-icon>
               <lib-icon *ngIf="!hideCardBodyEmail" [content]="icons.chevronUp"></lib-icon>
            </div>
         </form>
      </div>
      <div *ngIf="!hideCardBodyEmail" class="card-body">
         <div class="form-row">
            <div class="col-md-4 md-form form-group">
               <label for="email">{{ 'Email address' | translate }}</label>
               <input
                  id="email"
                  class="form-control"
                  type="email"
                  formControlName="email"
                  (change)="emailChange()"
               />
            </div>
            <!-- Removed until we actually use it for anything -->
            <!-- <div class="col-md-4 md-form form-group">
               <label for="email">{{ 'Valid until' | translate }}</label>
               <input
                  id="validity"
                  class="form-control"
                  type="datetime"
                  formControlName="validity"
               />
            </div> -->
            <div class="col-md-4 md-form form-group">
               <div class="custom-control custom-checkbox padded-checkbox" style="text-align: left">
                  <input
                     class="custom-control-input"
                     id="confirmed"
                     type="checkbox"
                     formControlName="confirmed"
                  />
                  <label style="color: #212529" class="custom-control-label" for="confirmed">{{
                     'Email confirmed' | translate
                  }}</label>
               </div>
            </div>
            <div class="col-md-12 md-form form-group">
               <div class="custom-control custom-checkbox" style="text-align: left">
                  <input
                     class="custom-control-input"
                     id="login"
                     type="checkbox"
                     formControlName="login"
                  />
                  <label class="custom-control-label" for="login">{{
                     'Login notification' | translate
                  }}</label>
               </div>
            </div>
            <div *ngIf="admin || manager" class="col-md-12 md-form form-group">
               <div class="custom-control custom-checkbox" style="text-align: left">
                  <input
                     class="custom-control-input"
                     id="block"
                     type="checkbox"
                     formControlName="block"
                  />
                  <label class="custom-control-label" for="block">{{
                     'Notification of blocked user' | translate
                  }}</label>
               </div>
            </div>
            <div *ngIf="admin" class="col-md-12 md-form form-group">
               <div class="custom-control custom-checkbox" style="text-align: left">
                  <input
                     class="custom-control-input"
                     id="news"
                     type="checkbox"
                     formControlName="news"
                  />
                  <label class="custom-control-label" for="news">{{
                     'Subscribe to newsletters' | translate
                  }}</label>
               </div>
            </div>
            <div *ngIf="admin || manager" class="col-md-12 md-form form-group">
               <div class="custom-control custom-checkbox" style="text-align: left">
                  <input
                     class="custom-control-input"
                     id="alert"
                     type="checkbox"
                     formControlName="alert"
                  />
                  <label class="custom-control-label" for="alert">{{
                     'Receive email for alerts' | translate
                  }}</label>
               </div>
            </div>
            <div class="col-md-12 md-form form-inline">
               <button
                  type="submit"
                  class="btn btn-primary"
                  (click)="onSubmitEmail()"
                  [disabled]="!emailSettings.valid"
               >
                  {{ 'Submit' | translate }}
               </button>
            </div>
         </div>
         <div
            class="alert alert-warning"
            style="margin-top: 1rem; margin-bottom: 0"
            *ngIf="!emailSettings.pristine"
         >
            {{ 'Pending changes, please submit.' | translate }}
         </div>
      </div>
   </div>
</form>

<div class="card border-dark">
   <div class="card-header" (click)="hideCardBodyVersion = !hideCardBodyVersion">
      <form class="form-inline">
         <b>{{ 'System version numbers' | translate }}</b>
         <div class="col" style="text-align: right">
            <lib-icon *ngIf="hideCardBodyVersion" [content]="icons.chevronDown"></lib-icon>
            <lib-icon *ngIf="!hideCardBodyVersion" [content]="icons.chevronUp"></lib-icon>
         </div>
      </form>
   </div>
   <div *ngIf="!hideCardBodyVersion" class="card-body">
      <div class="form-row">
         <div class="col-md-12 md-form">
            <label
               >{{ 'Portal version:' | translate }} <b>{{ version }}</b></label
            >
         </div>
         <div class="col-md-12 md-form">
            <label
               >{{ 'Portal API version:' | translate }} <b>{{ versions.api }}</b></label
            >
         </div>
         <div class="col-md-12 md-form">
            <label
               >{{ 'NMVS interface version:' | translate }} <b>{{ versions.nmvsVersion }}</b></label
            >
         </div>
         <div class="col-md-12 md-form">
            <label
               >{{ 'MedTracS interface version:' | translate }}
               <b>{{ versions.interfaceVersion }}</b></label
            >
         </div>
         <div class="col-md-12 md-form">
            <label
               >{{ 'Latest portable client version:' | translate }}
               <b>{{ versions.clientVersion }}</b></label
            >
         </div>
         <div class="col-md-12 md-form">
            <label
               >{{ 'Latest desktop client version:' | translate }}
               <b>{{ versions.desktopVersion }}</b></label
            >
         </div>
      </div>
   </div>
</div>
