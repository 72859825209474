/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { Subject } from 'rxjs';
import { HttpService, isResultValid, translate, TranslatePipe } from 'src/app/main/http-service';
import { ElemList } from 'src/app/main/select/select.handler';
import { AppComponent } from '../app.component';
import { getCsvMenus } from '../config/csv.options.config';
import { routes } from '../config/routes.config';
import { Icons } from '../handlers/icon.handler';
import { IconClass } from '../main/icon/icon.handler';
import {
   addIcon,
   center,
   Codes,
   convertDateToLocale,
   Headers,
   right,
} from '../main/table/table.handler';
import { IIconEvent } from '../main/table/table.interface';
import { translateHeaders, translateMenus } from '../main/table/table.translate';
import { CsvCallModes } from './csv-options';

@Component({
   selector: 'app-csv-download',
   templateUrl: './csv-download.component.html',
   styleUrls: ['./csv-download.component.css'],
})
export class CsvDownloadComponent implements OnInit {
   csvService: HttpService;

   csvDataService: HttpService;

   options = getCsvMenus();

   pageOptions = [];

   elemList: ElemList = [];

   loadCsvEvent: Subject<string> = new Subject<string>();

   loadCsvObs = this.loadCsvEvent.asObservable();

   unloadCsvEvent: Subject<void> = new Subject<void>();

   unloadCsvObs = this.unloadCsvEvent.asObservable();

   url = new UntypedFormGroup({
      number: new UntypedFormControl({ value: 10000, disabled: false }, [
         Validators.required,
         Validators.max(100000),
      ]),
      separator: new UntypedFormControl({ value: ';', disabled: false }, [Validators.required]),
      bigNumberToString: new UntypedFormControl({ value: true, disabled: false }),
   });

   csvList = [];

   params: Params;

   app = AppComponent;

   headers = new Headers([
      'File name',
      right('Size (kB)'),
      right('Create date'),
      center('Download'),
   ]);

   codes = new Codes(['fileName', right('size'), right('createDate'), center('download')]);

   constructor(private http: HttpClient, private route: ActivatedRoute) {
      this.csvService = new HttpService(this.http, routes.csv);
      this.csvDataService = new HttpService(http, routes.csvDownload);
      translateHeaders(this.headers, translate);
   }

   ngOnInit(): void {
      this.route.queryParams.subscribe((params) => {
         this.params = params;
         setTimeout(() => {
            const found = this.options.find((option) => this.params.url.startsWith(option._id));
            this.loadCsvEvent.next(found._id);
            this.url.get('menu').disable();
         }, 100);
      });
      this.pageOptions = translateMenus(
         this.options.map((csvOption) => ({ ...csvOption })),
         translate,
      );
      this.getCsvData();
      this.url.get('number').setValue(10000);
   }

   getCsvData(): void {
      this.csvDataService.post<any>({}, (result) => {
         if (isResultValid(result)) {
            this.csvList = result.body.csvList;
            this.csvList.forEach((l) => {
               l.size /= 10;
               l.size = Math.round(l.size) / 100;
            });
            convertDateToLocale(this.csvList, ['createDate'], TranslatePipe.getLocale());
            addIcon(
               this.csvList,
               'download',
               new IconClass(Icons.download, 'download', [], translate('Download')),
            );
         }
      });
   }

   downloadCSV(fileName: string): void {
      this.csvDataService.download({ fileName }, () => null);
   }

   onGenerateLists(): void {
      this.csvService.download(
         {
            mode: this.mapUrlToCsvMode(this.url.get('menu').value),
            ...this.params,
            from: 1,
            to: this.url.get('number').value,
            separator: this.url.get('separator').value,
            bigNumberToString: this.url.get('bigNumberToString').value,
         },
         () => {
            this.getCsvData();
         },
      );
   }

   mapUrlToCsvMode(uri: string): string {
      uri = decodeURIComponent(uri);
      switch (true) {
         case uri.startsWith('/sysadmin/data-queries/sessions'):
         case uri.startsWith('/sysadmin/search/sessions'):
         case uri.startsWith('/data-queries/sessions'):
         case uri.startsWith('/search/sessions'):
            return CsvCallModes.sessions;
         case uri.startsWith('/sysadmin/data-queries/tasks'):
         case uri.startsWith('/sysadmin/search/tasks'):
         case uri.startsWith('/data-queries/tasks'):
         case uri.startsWith('/search/tasks'):
            return CsvCallModes.tasks;
         case uri.startsWith('/sysadmin/data-queries/records'):
         case uri.startsWith('/data-queries/records'):
         case uri.startsWith('/sysadmin/data-queries/alerts'):
         case uri.startsWith('/sysadmin/search/alerts'):
         case uri.startsWith('/data-queries/alerts'):
         case uri.startsWith('/search/alerts'):
         case uri.startsWith('/sysadmin/search/records-data'):
         case uri.startsWith('/search/records-data'):
         case uri.startsWith('/sysadmin/search/datamatrix'):
            return CsvCallModes.records;
         case uri.startsWith('/sysadmin/data-queries/transactions'):
         case uri.startsWith('/sysadmin/search/transactions'):
         case uri.startsWith('/data-queries/transactions'):
         case uri.startsWith('/search/transactions'):
            return CsvCallModes.transactions;
         case uri.startsWith('/sysadmin/master-files/companies'):
            return CsvCallModes.companies;
         case uri.startsWith('/sysadmin/master-files/divisions'):
         case uri.startsWith('/sysadmin/search/divisions'):
            return CsvCallModes.divisions;
         case uri.startsWith('/sysadmin/master-files/certificates'):
         case uri.startsWith('/sysadmin/search/certificates'):
            return CsvCallModes.certificates;
         case uri.startsWith('/sysadmin/master-files/users'):
         case uri.startsWith('/sysadmin/search/users'):
            return CsvCallModes.users;
         case uri.startsWith('/sysadmin/master-files/terminals'):
            return CsvCallModes.terminals;
         case uri.startsWith('/sysadmin/master-files/products'):
         case uri.startsWith('/master-files/products'):
            return CsvCallModes.productMaster;
         case uri.startsWith('/sysadmin/master-files/wholesalers'):
         case uri.startsWith('/master-files/wholesalers'):
            return CsvCallModes.wholesalers;
         case uri.startsWith('/sysadmin/master-files/decommission-reasons'):
            return CsvCallModes.checkoutReasons;
         case uri.startsWith('/sysadmin/master-files/nmvs-errors'):
            return CsvCallModes.errorCodes;
         case uri.startsWith('/sysadmin/maintenances/users/divisions'):
         case uri.startsWith('/sysadmin/maintenances/users/rights'):
         case uri.startsWith('/sysadmin/search/user-divisions'):
         case uri.startsWith('/sysadmin/search/user-rights'):
            return CsvCallModes.rights;
         case uri.startsWith('/sysadmin/maintenances/users/manager'):
         case uri.startsWith('/sysadmin/search/managers'):
            return CsvCallModes.managers;
         case uri.startsWith('/sysadmin/maintenances/system-log'):
            return CsvCallModes.systemLog;
         case uri.startsWith('/sysadmin/maintenances/audit-log'):
            return CsvCallModes.auditLog;
         case uri.startsWith('/sysadmin/search/contacts'):
            return CsvCallModes.contacts;
         case uri.startsWith('/sysadmin/statistics/postgres'):
            return CsvCallModes.postgres;
         case uri.startsWith('/sysadmin/statistics/transaction-numbers'):
         case uri.startsWith('/statistics/transaction-numbers'):
            return CsvCallModes.transactionStats;
         case uri.startsWith('/sysadmin/statistics/record-numbers'):
         case uri.startsWith('/statistics/record-numbers'):
            return CsvCallModes.recordStats;
         case uri.startsWith('/sysadmin/statistics/statuses'):
         case uri.startsWith('/statistics/statuses'):
            return CsvCallModes.statusStats;
         case uri.startsWith('/sysadmin/statistics/alerts'):
         case uri.startsWith('/statistics/alerts'):
            return CsvCallModes.alertStats;
         case uri.startsWith('/sysadmin/statistics/runtime'):
         case uri.startsWith('/statistics/runtime'):
            return CsvCallModes.runtimeStats;
         case uri.startsWith('/sysadmin/statistics/nmvs'):
         case uri.startsWith('/statistics/nmvs'):
            return CsvCallModes.nmvsReturnCodesStats;
         case uri.startsWith('/sysadmin/statistics/batches-of-task'):
         case uri.startsWith('/statistics/batches-of-task'):
            return CsvCallModes.taskStatByBatch;
         case uri.startsWith('/sysadmin/statistics/batch'):
         case uri.startsWith('/statistics/batch'):
            return CsvCallModes.batchStats;
         default: {
            return CsvCallModes.default;
         }
      }
   }

   onIconClick(event: IIconEvent<any>): void {
      this.downloadCSV(event.row.fileName);
   }
}
