/* eslint-disable import/no-extraneous-dependencies */
import { NgModule } from '@angular/core';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale, huLocale, plLocale, roLocale, frLocale } from 'ngx-bootstrap/locale';

// eslint-disable-next-line no-shadow
export enum Locales {
   en = 'en-GB',
   hu = 'hu-HU',
   pl = 'pl-PL',
   ro = 'ro-RO',
   fr = 'fr-FR',
}

export function defineLocales(): void {
   defineLocale(Locales.en.toLocaleLowerCase(), enGbLocale);
   defineLocale(Locales.hu.toLocaleLowerCase(), huLocale);
   defineLocale(Locales.pl.toLocaleLowerCase(), plLocale);
   defineLocale(Locales.ro.toLocaleLowerCase(), roLocale);
   defineLocale(Locales.fr.toLocaleLowerCase(), frLocale);
}

@NgModule({
   declarations: [],
   imports: [],
   exports: [],
})
export class LocaleModule {
   constructor() {
      defineLocales();
   }
}
