/* eslint-disable import/no-extraneous-dependencies */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { IconModule } from '../icon/icon.module';
import { NavbarComponent } from './navbar.component';
import { SubmenuComponent } from './submenu/submenu.component';

@NgModule({
   declarations: [NavbarComponent, SubmenuComponent],
   imports: [
      CommonModule,
      RouterModule.forChild([]),
      BsDropdownModule.forRoot(),
      IconModule,
      TooltipModule,
   ],
   exports: [NavbarComponent, RouterModule],
})
export class NavbarModule {}
