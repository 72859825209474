<ng-template #template>
   <div class="modal-header">
      <!-- a modal címe -->
      <h4 class="modal-title pull-left">{{ title }}</h4>
      <!-- bezáró gomb -->
      <button
         *ngIf="closeButton"
         type="button"
         class="close pull-right"
         aria-label="Close"
         (click)="modalRef.hide()"
      >
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <form [formGroup]="modalForm">
      <div class="modal-body">
         <!-- magyarázó szöveg -->
         <label *ngIf="text">{{ text }}</label>
         <!-- végigmegyünk az inputokon, rendereljük mindet  -->
         <ng-container *ngIf="inputs.length > 0">
            <lib-alert *ngIf="isInvalid()" [data]="invalidData"></lib-alert>
            <div formArrayName="inputs">
               <div
                  *ngFor="let control of body; let i = index"
                  [ngClass]="control.label ? 'form-group' : null"
               >
                  <ng-container [ngSwitch]="control.type">
                     <ng-container *ngSwitchCase="ControlType.formInline">
                        <div class="form-group row" [hidden]="control.hidden">
                           <div class="col-3"> {{ control.label }} </div>
                           <div class="col-9">
                              <b> {{ control.value }} </b>
                           </div>
                        </div>
                     </ng-container>
                     <!-- ha nem form-inline -->
                     <ng-container *ngSwitchDefault>
                        <!-- cím kiírása a mezőhöz, ha létezik -->
                        <label *ngIf="control.label" for="{{ control.id }}">
                           {{ control.label }}
                        </label>
                        <ng-container [ngSwitch]="control.type">
                           <ng-container *ngSwitchCase="ControlType.textarea">
                              <textarea
                                 [formControlName]="i"
                                 id="{{ control.id }}"
                                 class="form-control"
                                 placeholder="{{ control.placeHolder }}"
                                 [(ngModel)]="control.value"
                              ></textarea>
                           </ng-container>
                           <ng-container *ngSwitchCase="ControlType.date">
                              <input
                                 [formControlName]="i"
                                 id="{{ control.id }}"
                                 type="date"
                                 class="form-control"
                                 placeholder="{{ control.placeHolder }}"
                                 [(ngModel)]="control.value"
                                 bsDatepicker
                                 [bsConfig]="bsConfig"
                                 triggers="click:click"
                                 (click)="onClick(control)"
                              />
                           </ng-container>
                           <ng-container *ngSwitchCase="ControlType.dateRange">
                              <input
                                 [formControlName]="i"
                                 id="{{ control.id }}"
                                 type="text"
                                 class="form-control"
                                 placeholder="{{ control.placeHolder }}"
                                 [(ngModel)]="control.value"
                                 bsDaterangepicker
                                 [bsConfig]="bsConfig"
                                 triggers="click:click"
                              />
                           </ng-container>
                           <ng-container *ngSwitchCase="ControlType.checkbox">
                              <div
                                 class="custom-control custom-checkbox"
                                 [ngStyle]="i === 0 ? { 'margin-top': '0px' } : ''"
                              >
                                 <input
                                    [formControlName]="i"
                                    class="custom-control-input"
                                    id="{{ control.id }}"
                                    type="checkbox"
                                    [ngModel]="control.value"
                                    [indeterminate]="control.indeterminate"
                                    (click)="onCheckBox(control)"
                                 />
                                 <label class="custom-control-label" for="{{ control.id }}">{{
                                    control.placeHolder
                                 }}</label>
                              </div>
                           </ng-container>
                           <ng-container *ngSwitchCase="ControlType.dateTime">
                              <div class="row">
                                 <div class="col-6">
                                    <input
                                       [formControlName]="i"
                                       id="{{ control.id }}"
                                       type="date"
                                       class="form-control"
                                       placeholder="{{ control.placeHolder }}"
                                       [(ngModel)]="control.value"
                                       bsDatepicker
                                       [bsConfig]="bsConfig"
                                       triggers="click:click"
                                       (click)="onClick(control)"
                                    />
                                 </div>
                                 <div class="col-6">
                                    <timepicker
                                       [formControlName]="i + 1"
                                       id="{{ body[i + 1].id }}"
                                       [(ngModel)]="body[i + 1].value"
                                       [showMeridian]="false"
                                       [showSpinners]="false"
                                       [showSeconds]="true"
                                    >
                                    </timepicker>
                                 </div>
                              </div>
                           </ng-container>
                           <ng-container *ngSwitchCase="ControlType.select">
                              <select
                                 class="custom-select"
                                 [formControlName]="i"
                                 id="{{ control.id }}"
                                 [(ngModel)]="control.value"
                              >
                                 <option value="undefined" hidden>{{ control.default }}</option>
                                 <option
                                    *ngFor="let option of control.options"
                                    value="{{
                                       option._id ? option._id : control.options.indexOf(option)
                                    }}"
                                 >
                                    {{ getFieldValue(option, control.field) }}
                                 </option>
                              </select>
                           </ng-container>
                           <ng-container *ngSwitchCase="ControlType.radios">
                              <div
                                 class="custom-control custom-radio"
                                 *ngFor="let option of control.options; let j = index"
                              >
                                 <input
                                    type="radio"
                                    [id]="control.id + j"
                                    [name]="control.id"
                                    class="custom-control-input"
                                    [checked]="control.value === j"
                                    (click)="onRadio(control, j)"
                                 />
                                 <label class="custom-control-label" [for]="control.id + j">{{
                                    option
                                 }}</label>
                              </div>
                           </ng-container>
                           <ng-container *ngSwitchDefault>
                              <ng-container *ngIf="control.type !== ControlType.null">
                                 <input
                                    [formControlName]="i"
                                    id="{{ control.id }}"
                                    type="{{ control.type }}"
                                    class="form-control"
                                    placeholder="{{ control.placeHolder }}"
                                    [(ngModel)]="control.value"
                                    autocomplete="{{ control.id }}"
                                    [hidden]="control.hidden"
                                 />
                              </ng-container>
                           </ng-container>
                        </ng-container>
                     </ng-container>
                  </ng-container>
               </div>
            </div>
         </ng-container>
      </div>
      <div class="modal-footer">
         <div class="form-inline">
            <ng-container *ngFor="let button of buttons">
               <button
                  *ngIf="button.classes.includes('left')"
                  id="{{ button.id }}"
                  class="btn"
                  [ngClass]="button.classes"
                  type="{{ button.type }}"
                  (click)="onButton($event)"
                  [disabled]="button.checkInvalid ? modalForm.invalid : null"
               >
                  {{ button.value }}
               </button>
            </ng-container>
            <div class="col" style="text-align: right">
               <ng-container *ngFor="let button of buttons">
                  <button
                     *ngIf="!button.classes.includes('left')"
                     id="{{ button.id }}"
                     class="btn"
                     [ngClass]="button.classes"
                     type="{{ button.type }}"
                     style="margin-left: 4px"
                     (click)="onButton($event)"
                     [disabled]="button.checkInvalid ? modalForm.invalid : null"
                  >
                     {{ button.value }}
                  </button>
               </ng-container>
            </div>
         </div>
      </div>
   </form>
</ng-template>
