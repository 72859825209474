<div class="card border-dark">
   <div class="card-header" (click)="hideCardBody = !hideCardBody">
      <form class="form-inline">
         <b>{{ 'Information about rights' | translate }}</b>
         <div class="col" style="text-align: right">
            <lib-icon *ngIf="hideCardBody" [content]="icons.chevronDown"></lib-icon>
            <lib-icon *ngIf="!hideCardBody" [content]="icons.chevronUp"></lib-icon>
         </div>
      </form>
   </div>
   <div *ngIf="!hideCardBody" class="card-body">
      <div class="form-row">
         <div class="col-md-12 md-form">
            <label
               ><b>{{ 'Manager:' | translate }}</b
               >{{ ' '
               }}{{
                  'enables assignment of other users, for their data management.' | translate
               }}</label
            >
         </div>
         <div class="col-md-12 md-form">
            <label
               ><b>{{ 'Change lang.:' | translate }}</b
               >{{ ' ' }}{{ 'enables user to change language.' | translate }}</label
            >
         </div>
         <div class="col-md-12 md-form">
            <label style="display: block"
               ><b>{{ 'Task auth.:' | translate }}</b
               >{{ ' '
               }}{{
                  'right of task authorization for this user under aggregated codes.' | translate
               }}
               <div style="padding-left: 10rem">
                  <li>{{ 'Empty: the user only see his own tasks.' | translate }}</li>
                  <li>{{
                     'Check: the user can not see his own tasks, only for verification.' | translate
                  }}</li>
                  <li>{{
                     'Green: verification right, the user sees aggregated tasks of other users.'
                        | translate
                  }}</li>
               </div>
            </label>
         </div>
         <br />
         <div class="col-md-12 md-form">
            <label
               ><b>{{ 'Revert others:' | translate }}</b
               >{{ ' ' }}{{ 'enable user to revert records of other users.' | translate }}</label
            >
         </div>
      </div>
   </div>
</div>
