<lib-table
   [headers]="headers"
   [codes]="codes"
   [rows]="divisions"
   [readOnly]="true"
   [orderBy]="true"
   [label]="
      ('User' | translate) +
      ': \'' +
      user +
      '\' - ' +
      ('Current division' | translate) +
      ': \'' +
      division +
      '\''
   "
   (orderByEvent)="onOrderBy($event)"
>
</lib-table>
<div class="form-row fixwidth">
   <div *ngFor="let act of news" class="col-lg-4 col-md-6 col-sm-12 form-group">
      <div class="card border-dark">
         <div class="card-header">
            <h4>{{ act.title }}</h4
            >{{ act.createDate }}
         </div>
         <div class="card-body">
            <div [innerHTML]="act.text" style="padding: 5%"></div>
         </div>
      </div>
   </div>
</div>
