import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export type InvalidDataType = (UntypedFormControl | UntypedFormArray | UntypedFormGroup | string[][])[][];

export function AddInvalidControl(
   invalidData: InvalidDataType,
   control: UntypedFormControl | UntypedFormArray | UntypedFormGroup,
   error: string[],
): void {
   invalidData.splice(0, 0, [control, [error]]);
}

export function AddInvalidError(
   invalidData: InvalidDataType,
   control: UntypedFormControl | UntypedFormArray | UntypedFormGroup,
   error: string[],
): void {
   // eslint-disable-next-line no-restricted-syntax
   for (const data of invalidData) {
      if (data[0] === control) {
         const index = invalidData.indexOf(data);
         (invalidData[index][1] as string[][]).splice(0, 0, error);
         break;
      }
   }
}
