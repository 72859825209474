/* eslint-disable no-shadow */
export enum CsvCallModes {
   sessions = 'sessions',
   tasks = 'tasks',
   records = 'records',
   transactions = 'transactions',
   companies = 'companies',
   divisions = 'divisions',
   certificates = 'certificates',
   users = 'users',
   terminals = 'terminals',
   productMaster = 'product-master',
   wholesalers = 'wholesalers',
   checkoutReasons = 'checkout-reasons',
   errorCodes = 'error-codes',
   rights = 'rights',
   managers = 'managers',
   systemLog = 'system-log',
   auditLog = 'audit-log',
   contacts = 'contacts',
   postgres = 'postgres',
   transactionStats = 'transaction-stats',
   recordStats = 'record-stats',
   statusStats = 'status-stats',
   alertStats = 'alert-stats',
   runtimeStats = 'runtime-stats',
   nmvsReturnCodesStats = 'nmvs-return-codes-stats',
   batchStats = 'batch-stats',
   taskStatByBatch = 'task-stat-by-batch',
   default = 'default',
}