<div class="card border-dark">
   <div
      class="card-header"
      *ngIf="label || filter || search || headerButtons || toggle !== undefined"
      (click)="onToggle()"
   >
      <form class="form-inline">
         <ng-container *ngIf="label">
            <b>{{ label }}</b>
         </ng-container>
         <div class="col" style="text-align: right">
            <div style="display: inline-flex">
               <ng-container *ngIf="filter">
                  <input
                     class="form-control"
                     id="{{ 'filter' + (id ? id : '') }}"
                     style="height: 2rem"
                     type="search"
                     [placeholder]="filter"
                     #control
                     (search)="onFilter(control.value)"
                     (keyup)="onFilter(control.value)"
                     (click)="$event.stopPropagation()"
                  />
               </ng-container>
               <ng-container *ngIf="search">
                  <button
                     class="btn btn-primary btn-sm"
                     style="margin-left: 0.25rem"
                     [ngClass]="{ 'btn-warning': filterKeys }"
                     (click)="searchEvent.emit(); $event.stopPropagation()"
                  >
                     <lib-icon [content]="'fas:faSearch'"> </lib-icon>
                  </button>
               </ng-container>
               <ng-container *ngIf="refresh">
                  <button
                     class="btn btn-primary btn-sm"
                     style="margin-left: 0.25rem"
                     (click)="refreshEvent.emit(); $event.stopPropagation()"
                  >
                     <lib-icon [content]="'fas:faSyncAlt'"> </lib-icon>
                  </button>
               </ng-container>
               <ng-container *ngIf="headerButtons">
                  <div *ngFor="let button of headerButtons; let i = index">
                     <button
                        class="btn btn-primary btn-sm"
                        (click)="headerEvent.emit(i); $event.stopPropagation()"
                        type="button"
                        style="margin-left: 0.25rem"
                        [id]="'button' + i"
                        >{{ button }}</button
                     >
                  </div>
               </ng-container>
               <ng-container *ngIf="toggle !== undefined">
                  <div style="padding-top: 0.25rem; padding-left: 1rem">
                     <lib-icon *ngIf="!toggle" [content]="icons.chevronDown"></lib-icon>
                     <lib-icon *ngIf="toggle" [content]="icons.chevronUp"></lib-icon>
                  </div>
               </ng-container>
            </div>
         </div>
      </form>
   </div>
   <div [hidden]="toggle !== undefined && !toggle">
      <div class="card-body">
         <div
            class="col"
            [ngStyle]="{ 'text-align': pagination.align }"
            *ngIf="isPagination(true)"
            style="margin-bottom: 1rem"
         >
            <div style="display: inline-flex">
               <pagination
                  [ngModel]="pagination.currentPage"
                  [totalItems]="pagination.totalItems"
                  [itemsPerPage]="pagination.itemsPerPage"
                  [nextText]="pagination.nextText"
                  [previousText]="pagination.previousText"
                  [maxSize]="pagination.maxSize"
                  [rotate]="pagination.rotate"
                  [boundaryLinks]="pagination.boundaryLinks"
                  [firstText]="pagination.firstText"
                  [lastText]="pagination.lastText"
                  (pageChanged)="onPageChanged($event)"
               >
               </pagination>
            </div>
         </div>
         <div class="table-responsive">
            <table
               class="table table-sm table-hover"
               [ngClass]="multiRow ? 'table-bordered' : null"
            >
               <thead *ngIf="headers">
                  <tr *ngFor="let row of headers.rows; let i = index">
                     <th
                        *ngIf="nr && i === 0"
                        [attr.rowspan]="headers.rows.length"
                        [ngClass]="multiRow ? 'text-center' : null"
                     >
                        {{ nr }}
                     </th>
                     <th
                        scope="col"
                        *ngFor="let header of row; let j = index"
                        [attr.rowspan]="header.rowspan"
                        [attr.colspan]="header.colspan"
                        [ngClass]="[
                           header.classes ? header.classes.join(' ') : '',
                           multiRow ? 'center' : ''
                        ]"
                        (click)="onHeaderClick(i, j)"
                     >
                        {{ header.str ? header.str : header }}
                        <ng-container
                           *ngIf="orderBy && orderedBy?.orderBy === codes.fields[i][j].str"
                        >
                           <lib-icon
                              [content]="
                                 orderedBy.direction === 'asc' ? 'fas:faArrowUp' : 'fas:faArrowDown'
                              "
                           ></lib-icon>
                        </ng-container>
                     </th>
                  </tr>
               </thead>
               <tbody>
                  <ng-container *ngFor="let row of rows; let i = index">
                     <tr
                        *ngFor="let fields of codes.fields; let j = index"
                        [hidden]="!getRowVisible(row)"
                        (click)="onRowClick(row)"
                        [ngClass]="[getRowStyles(row), i === selected ? 'table-active' : '']"
                     >
                        <!-- serial number of row -->
                        <th *ngIf="nr" [ngClass]="multiRow ? 'text-center' : null">
                           #{{
                              desc
                                 ? pagination.totalItems -
                                   pagination.itemsPerPage * (pagination.currentPage - 1) -
                                   i
                                 : pagination.itemsPerPage * (pagination.currentPage - 1) + i + 1
                           }}
                        </th>
                        <!-- other fields listed in the order of codes -->
                        <ng-container *ngFor="let code of fields">
                           <ng-container *ngFor="let fieldValue of [getFieldValue(row, code.str)]">
                              <td
                                 *ngIf="
                                    fieldValue !== null && fieldValue !== undefined;
                                    else emptyCol
                                 "
                                 [ngClass]="[
                                    code.classes ? code.classes.join(' ') : '',
                                    multiRow ? 'center' : '',
                                    isModal(fieldValue) || hasTooltip(fieldValue)
                                       ? 'table-fix-content'
                                       : ''
                                 ]"
                                 [attr.rowspan]="code.rowspan"
                                 [attr.colspan]="code.colspan"
                                 [ngStyle]="
                                    code.classes &&
                                    code.classes.includes('empty-padding') &&
                                    fieldValue.trim() === ''
                                       ? { padding: '0' }
                                       : null
                                 "
                              >
                                 <ng-container [ngSwitch]="true">
                                    <input
                                       *ngSwitchCase="
                                          inputs && (inputs[0].includes(i) || inputs[1].includes(j))
                                       "
                                       id="{{ 'input' + i + '' + j }}"
                                       class="form-control"
                                       type="text"
                                       value="{{ fieldValue }}"
                                       (focusout)="
                                          focusOut.emit({
                                             row: row,
                                             value: $event.target.value,
                                             rowId: i,
                                             columnId: j
                                          })
                                       "
                                    />
                                    <!-- if this field contains icons -->
                                    <lib-icon
                                       *ngSwitchCase="isIcons([fieldValue])"
                                       [content]="fieldValue.content"
                                       [libTooltip]="fieldValue.tooltip"
                                       [ngClass]="[
                                          fieldValue.classes ? fieldValue.classes.join(' ') : ''
                                       ]"
                                       (iClicked)="
                                          iconEvent.emit({
                                             row: row,
                                             icon: fieldValue.content,
                                             id: fieldValue.id
                                          })
                                       "
                                       (click)="$event.stopPropagation()"
                                    >
                                    </lib-icon>
                                    <!-- if this field is boolean -->
                                    <div
                                       *ngSwitchCase="isBoolean(fieldValue)"
                                       class="custom-control custom-checkbox"
                                       (click)="$event.stopPropagation()"
                                    >
                                       <input
                                          class="custom-control-input"
                                          id="{{ code.str + '_' + rows.indexOf(row) + '_' + id }}"
                                          type="checkbox"
                                          [checked]="
                                             fieldValue.value === undefined
                                                ? fieldValue
                                                : fieldValue.value
                                          "
                                          (click)="
                                             $event.stopPropagation();
                                             onCheckBox(row, code.str);
                                             checkEvent.emit({ row: row, elem: code.str })
                                          "
                                          [disabled]="(isReadOnly(row) && !isWritableInReadonly(code)) || isAlwaysReadonly(code)"
                                          [indeterminate]="fieldValue.indeterminate"
                                       />
                                       <label
                                          class="custom-control-label"
                                          for="{{ code.str + '_' + rows.indexOf(row) + '_' + id }}"
                                       ></label>
                                    </div>
                                    <!-- if this field is a link -->
                                    <ng-container *ngSwitchCase="isLink(fieldValue)">
                                       <a
                                          *ngIf="fieldValue.tab; else sameSite"
                                          [routerLink]="routerLink"
                                          [queryParams]="router.parseUrl(router.url).queryParams"
                                          (click)="
                                             onLinkClick(fieldValue); $event.stopPropagation()
                                          "
                                       >
                                          {{ fieldValue.value }}</a
                                       >
                                       <ng-template #sameSite>
                                          <a
                                             [routerLink]="fieldValue.link"
                                             [queryParams]="fieldValue.params"
                                             >{{ fieldValue.value }}</a
                                          >
                                       </ng-template>
                                    </ng-container>
                                    <!-- if this field is a button -->
                                    <button
                                       *ngSwitchCase="isButton(fieldValue)"
                                       [ngClass]="[
                                          'btn',
                                          'btn-sm',
                                          fieldValue.classes
                                             ? fieldValue.classes.join(' ')
                                             : 'btn-primary'
                                       ]"
                                       (click)="
                                          buttonEvent.emit({ row: row, id: code });
                                          $event.stopPropagation()
                                       "
                                    >
                                       {{ fieldValue.button }}
                                    </button>
                                    <!-- if this field is color -->
                                    <input
                                       *ngSwitchCase="isColor(fieldValue)"
                                       [style.background]="fieldValue"
                                       [disabled]="isReadOnly(row) && readonlyColor(row)"
                                       [cpDisabled]="isReadOnly(row) && readonlyColor(row)"
                                       style="width: 8rem"
                                       [colorPicker]="fieldValue"
                                       [cpOutputFormat]="'hex'"
                                       [cpAlphaChannel]="'disabled'"
                                       (colorPickerSelect)="
                                          setFieldValue(row, code.str, $event);
                                          colorEvent.emit({ row: row, elem: code.str })
                                       "
                                    />
                                    <!-- if this field is a modal -->
                                    <div
                                       *ngSwitchCase="isModal(fieldValue)"
                                       class="fix-cell-content"
                                       style="cursor: pointer"
                                       (click)="openModal(fieldValue)"
                                    >
                                       {{ fieldValue.value }}
                                    </div>
                                    <!-- if field has tooltip -->
                                    <div
                                       *ngSwitchCase="hasTooltip(fieldValue)"
                                       class="fix-cell-content"
                                       [tooltip]="fieldValue.tooltip"
                                       >{{ fieldValue.value }}</div
                                    >
                                    <!-- default: normal text -->
                                    <ng-container *ngSwitchDefault>
                                       {{ fieldValue }}
                                    </ng-container>
                                 </ng-container>
                              </td>
                              <ng-template #emptyCol>
                                 <td [attr.rowspan]="code.rowspan" [attr.colspan]="code.colspan">
                                 </td>
                              </ng-template>
                           </ng-container>
                        </ng-container>
                     </tr>
                  </ng-container>
               </tbody>
            </table>
         </div>
      </div>
      <div class="card-footer" *ngIf="isPagination(false)">
         <div class="col" [ngStyle]="{ 'text-align': pagination.align }">
            <div style="display: inline-flex">
               <pagination
                  [ngModel]="pagination.currentPage"
                  [totalItems]="pagination.totalItems"
                  [itemsPerPage]="pagination.itemsPerPage"
                  [nextText]="pagination.nextText"
                  [previousText]="pagination.previousText"
                  [maxSize]="pagination.maxSize"
                  [rotate]="pagination.rotate"
                  [boundaryLinks]="pagination.boundaryLinks"
                  [firstText]="pagination.firstText"
                  [lastText]="pagination.lastText"
                  (pageChanged)="onPageChanged($event)"
               >
               </pagination>
            </div>
         </div>
      </div>
   </div>
</div>
