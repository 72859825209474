<form [formGroup]="url">
   <div class="card border-dark">
      <div class="card-header">
         <form class="form-inline">
            <b>{{ 'CSV download' | translate }}</b>
            <div class="col" style="text-align: right"> </div>
         </form>
      </div>
      <div class="card-body">
         <div class="form-row">
            <div class="col-md-4 md-form form-group">
               <lib-select
                  [elemList]="elemList"
                  [header]="'Select page' | translate"
                  [options]="pageOptions"
                  [field]="'menu'"
                  [required]="true"
                  [load]="loadCsvObs"
                  [unload]="unloadCsvObs"
                  [chooseStr]="'Choose' | translate"
                  [myName]="'menu'"
                  [myGroup]="url"
               >
               </lib-select>
            </div>
            <div class="col-md-4 md-form form-group">
               <label for="number">{{ 'Number of records' | translate }}</label>
               <input
                  type="number"
                  id="number"
                  class="form-control"
                  formControlName="number"
                  [placeholder]="'Number of records' | translate"
                  number
               />
            </div>
            <div class="col-md-2 md-form form-group">
               <label for="separator">{{ 'Separator' | translate }}</label>
               <input
                  type="text"
                  id="separator"
                  class="form-control"
                  formControlName="separator"
                  [placeholder]="'Separator' | translate"
               />
            </div>
            <div class="col-md-2 md-form form-group" style="padding-top: 2.3rem">
               <div class="custom-control custom-checkbox" style="text-align: left">
                  <input
                     class="custom-control-input"
                     id="bigNumberToString"
                     type="checkbox"
                     formControlName="bigNumberToString"
                  />
                  <label class="custom-control-label" for="bigNumberToString">{{
                     'Big numbers to string' | translate
                  }}</label>
               </div>
            </div>
            <div class="col-md-12 md-form form-inline">
               <div>
                  <button
                     type="submit"
                     class="btn btn-primary"
                     (click)="onGenerateLists()"
                     [disabled]="!url.valid"
                  >
                     {{ 'Download' | translate }}
                  </button>
               </div>
            </div>
         </div>
      </div>
   </div>
</form>

<lib-table
   [headers]="headers"
   [codes]="codes"
   [rows]="csvList"
   [readOnly]="true"
   [label]="'Already downloaded files' | translate"
   (iconEvent)="onIconClick($event)"
>
</lib-table>
