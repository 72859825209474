import { TranslatePipe } from 'src/app/main/http-service';
import { ControlType } from 'src/app/main/modal/body.handler';
import { ButtonType } from 'src/app/main/modal/button.handler';
import { InitButton } from 'src/app/main/modal/modal.button';
import { ModalHandler } from 'src/app/main/modal/modal.handler';

export function chooseLanguageHandler(): ModalHandler {
   const handler: ModalHandler = new ModalHandler();
   handler.title = 'Choose language';
   handler.body = [
      {
         id: 'choose-language',
         field: 'LANG',
         label: 'Language:',
         type: ControlType.select,
         default: 'Choose',
         required: true,
         placeHolder: 'Language',
         options: [
            { _id: 'en', LANG: 'English' },
            { _id: 'hu', LANG: 'Magyar' },
            { _id: 'pl', LANG: 'Polski' },
            { _id: 'ro', LANG: 'Romana' },
            { _id: 'fr', LANG: 'Française' },
         ],
         value: TranslatePipe.getLocaleKey(TranslatePipe.getLocale()),
      },
   ];
   [handler.buttons] = InitButton({ type: ButtonType.CancelOk, prefix: 'choose-language' }, 1);
   return handler;
}
