/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { environment } from 'src/environments/environment';
import { NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { Locales } from 'src/app/main/modal/locale.module';

export const bsConfig: Partial<BsDatepickerConfig> = {
   containerClass:
      environment.stage || environment.build
         ? 'theme-orange'
         : environment.production
         ? 'theme-dark-blue'
         : 'theme-green',
   isAnimated: true,
   adaptivePosition: true,
   dateInputFormat: 'YYYY-MM-DD',
};

export const langLocales = {
   ENG: Locales.en,
   HUN: Locales.hu,
   POL: Locales.pl,
   RON: Locales.ro,
   FRA: Locales.fr,
};

export const spinnerColor =
   environment.stage || environment.build
      ? '#ffc107'
      : environment.production
      ? '#007bff'
      : '#28a745';
export const consentOkColor =
   environment.stage || environment.build
      ? '#ffc107'
      : environment.production
      ? '#007bff'
      : '#28a745';
export const mtsLogo = environment.production
   ? 'assets/mts-logo.png'
   : environment.build
   ? 'assets/mts-logo-dark.png'
   : 'assets/mts-logo-test.png';
export const mtsLoginLogo =
   environment.production || environment.build
      ? 'assets/mts-logo-dark.png'
      : 'assets/mts-logo-test.png';
export const btnStyle =
   environment.stage || environment.build
      ? ['btn-warning']
      : environment.production
      ? ['btn-primary']
      : ['btn-success'];
export const bgStyle =
   environment.stage || environment.build
      ? ['bg-warning']
      : environment.production
      ? ['bg-primary']
      : ['bg-success'];

export const cookieConfig: NgcCookieConsentConfig = {
   cookie: {
      domain: environment.cookie.domain,
      secure: environment.cookie.secure,
   },
   position: 'bottom-right',
   palette: {
      popup: {
         background: '#f8f9fa',
         text: '#000000',
      },
      button: {
         background: '#007bff',
      },
   },
   theme: 'block',
   type: 'info',
   content: {
      message: 'This website uses cookies to ensure you get the best experience on our website.',
      dismiss: 'Got it!',
      link: 'Learn more',
      href: 'api/privacy?lang=eng',
   },
   autoOpen: false,
};
