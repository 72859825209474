/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive } from '@angular/core';
import {
   AbstractControl as FormGroup,
   NG_VALIDATORS,
   ValidationErrors,
   Validator,
} from '@angular/forms';

@Directive({
   selector: '[scheduledMaintenanceValidator]',
   providers: [
      { provide: NG_VALIDATORS, useExisting: ScheduledMaintenanceValidatorDirective, multi: true },
   ],
})
export class ScheduledMaintenanceValidatorDirective implements Validator {
   // eslint-disable-next-line no-useless-constructor
   constructor() { }

   registerOnValidatorChange(fn: () => void): void { }

   validate(control: FormGroup): ValidationErrors | null {

      const setError=(ctrlNames: string[]) => {
         ctrlNames.forEach((ctrlName) => {

         control.get(ctrlName).setErrors({ schduledMaintenanceError: true });
         control.get(ctrlName).markAsTouched();
      });
   };

      const removeError=(ctrlNames: string[]) => {
         ctrlNames.forEach((ctrlName) => {
            control.get(ctrlName).setErrors(null);
         });
      };

      if (control.get('newsTitle').value || control.get('newsBody').value ||
         control.get('newsPublishDate').value || control.get('newsPublishTime').value) {

         if( !(control.get('newsTitle').value.length > 0 && control.get('newsBody').value.length > 0 &&
            control.get('newsPublishDate').value instanceof Date && control.get('newsPublishTime').value.length > 0)) {
               setError(["newsTitle","newsBody", "newsPublishDate", "newsPublishTime"]);
               return { schduledMaintenanceError: true};
         } 
      }
      removeError(["newsTitle", "newsBody", "newsPublishDate", "newsPublishTime"]);

      if(control.get('broadcastStartDate').value || control.get('broadcastStartTime').value ||
         control.get('broadcastEndDate').value || control.get('broadcastEndTime').value ||
         control.get('broadcastEng').value || control.get('broadcastHun').value ||
         control.get('broadcastPol').value || control.get('broadcastRon').value ) {

         if ( !(control.get('broadcastStartDate').value instanceof Date && control.get('broadcastStartTime').value.length > 0 &&
            control.get('broadcastEndDate').value instanceof Date && control.get('broadcastEndTime').value.length > 0 &&
            control.get('broadcastEng').value.length > 0)) {
                  setError(["broadcastStartDate", "broadcastStartTime", "broadcastEndDate", "broadcastEndTime", "broadcastEng"]);
                  return { schduledMaintenanceError: true};
         }
      } 
      removeError(["broadcastStartDate", "broadcastStartTime", "broadcastEndDate", "broadcastEndTime", "broadcastEng"]);

      return null;
   }
}
