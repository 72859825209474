import { translate } from 'src/app/main/http-service';
import { ControlType } from 'src/app/main/modal/body.handler';
import { ButtonType } from 'src/app/main/modal/button.handler';
import { InitButton } from 'src/app/main/modal/modal.button';
import { ModalHandler } from 'src/app/main/modal/modal.handler';
import { AppComponent } from '../app.component';

export const options = [
   { _id: 'win32', value: translate('Windows 32 bit') },
   { _id: 'win64', value: translate('Windows 64 bit') },
   { _id: 'win32-zip', value: translate('Windows 32 bit .zip') },
   { _id: 'win64-zip', value: translate('Windows 64 bit .zip') },
   { _id: 'arm64', value: translate('Android 64-bit (arm64-v8a) for newer devices') },
   { _id: 'armeabi', value: translate('Android 32-bit (armeabi-v7a) for older devices') },
];

export const optionsKeys = options.map(k => k._id);

export function downloadAppHandler(): ModalHandler {
   const handler: ModalHandler = new ModalHandler();
   handler.title = 'Download application';
   handler.body = [
      {
         id: 'app-options',
         field: 'value',
         label: 'Version:',
         type: ControlType.select,
         default: 'Choose',
         required: true,
         placeHolder: 'Version',
         options,
      },
   ];

   [handler.buttons] = InitButton({ type: ButtonType.OkCancel, prefix: 'download' }, 1);
   return handler;
}
