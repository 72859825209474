import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { HttpService, isResultValid } from 'src/app/main/http-service';
import { routes } from '../config/routes.config';
import { mtsLoginLogo } from '../config/app.config';

@Component({
   selector: 'app-email-confirm',
   templateUrl: './email-confirm.component.html',
   styleUrls: ['./email-confirm.component.css'],
})
export class EmailConfirmComponent implements OnInit {
   service: HttpService;

   emailOk = false;

   emailBad = false;

   emailConfirmed = false;

   mtsLogo = mtsLoginLogo;

   constructor(private http: HttpClient, private route: ActivatedRoute) {
      this.service = new HttpService(this.http, routes.emailConfirm);
   }

   ngOnInit(): void {
      this.route.params.subscribe((paramMap) => {
         if (paramMap.key) {
            this.confirmKey(paramMap.key);
         }
      });
   }

   confirmKey(key: string): void {
      this.service.post({ key }, (res) => {
         if (isResultValid(res)) {
            this.emailOk = true;
            this.emailBad = false;
            this.emailConfirmed = false;
         } else if (res.status === 409) {
            this.emailBad = false;
            this.emailOk = false;
            this.emailConfirmed = true;
         } else {
            this.emailBad = true;
            this.emailOk = false;
            this.emailConfirmed = false;
         }
      });
   }
}
