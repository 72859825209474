/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { IObject } from './observables';

export class IType {
   field: string;

   modifier?: string;

   value: any;

   constructor(field: string, value: any, modifier?: string) {
      this.field = field;
      this.value = value;
      if (modifier) {
         this.modifier = modifier;
      }
   }

   toString(): string {
      if (this.modifier) {
         return `${this.field}$${this.modifier}$${
            this.value instanceof Array ? this.value.join(',') : this.value
         }`;
      }
      return this.value !== undefined ? `${this.field}=${this.value}` : '';
   }

   toObject(): IObject {
      const obj: IObject = {};
      if (this.modifier) {
         const mod: IObject = {};
         mod[`$${this.modifier}`] = this.value;
         obj[this.field] = mod;
      } else if (this.value !== undefined) {
         obj[this.field] = this.value;
      }
      return obj;
   }
}
