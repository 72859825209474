/* eslint-disable no-useless-constructor */
import { Injectable } from '@angular/core';
import {
   ActivatedRouteSnapshot,
   CanActivateChild,
   Router,
   RouterStateSnapshot,
} from '@angular/router';
import { AppComponent } from '../app.component';
import { paths } from '../config/paths.config';

@Injectable({
   providedIn: 'root',
})
export class SysadminActivateChildService implements CanActivateChild {
   constructor(private router: Router) {}

   canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
      if (AppComponent.session.rights) {
         if (AppComponent.isRight('sys')) {
            return true;
         }
         this.router.navigate([paths.home]);
         return false;
      }
      AppComponent.logoutEvent.next();
      return false;
   }
}
