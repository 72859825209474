import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SelectModule } from '../select/select.module';
import { UserSelectComponent } from './user-select.component';

@NgModule({
   declarations: [UserSelectComponent],
   imports: [FormsModule, CommonModule, SelectModule],
   exports: [UserSelectComponent],
})
export class UserSelectModule {}
