import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isIcons } from '../icon/icon.handler';
import { NavbarHandler } from './navbar.handler';

@Component({
   selector: 'lib-navbar',
   templateUrl: './navbar.component.html',
   styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent {
   @Input() handler: NavbarHandler;

   @Output() button = new EventEmitter<string>();

   isIcons = isIcons;

   onButton(id: string): void {
      this.button.emit(id);
   }
}
