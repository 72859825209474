/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/no-extraneous-dependencies */
import {
   AfterViewChecked,
   Component,
   EventEmitter,
   Input,
   OnDestroy,
   OnInit,
   Output,
   TemplateRef,
   ViewChild,
} from '@angular/core';
import { AsyncValidatorFn, UntypedFormArray, UntypedFormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Observable, Subject, Subscription } from 'rxjs';
import { AddInvalidControl, InvalidDataType } from '../alert/alert.validator';
import { getFieldValue } from '../table/table.handler';
import { Body, ControlType, IElement, IModalBody } from './body.handler';
import { IModalButton } from './button.handler';
import { ChangeType, ModalHandler } from './modal.handler';

@Component({
   selector: 'lib-modal',
   templateUrl: './modal.component.html',
   styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements OnInit, AfterViewChecked, OnDestroy {
   // eslint-disable-next-line no-useless-constructor
   constructor(
      public modalService: BsModalService,
      private localeService: BsLocaleService,
      private fb: UntypedFormBuilder,
   ) { }

   @ViewChild('template') template: TemplateRef<any>;

   @Input() set handler(handler: ModalHandler) {
      this.obs = handler.obs;
      this.title = handler.title;
      this.localTime = handler.localTime;
      if (handler.locale) {
         this.localeService.use(handler.locale);
      }
      if (handler.bsConfig) {
         this.bsConfig = handler.bsConfig;
      }
      this.reqAlert = handler.reqAlert;
      if (handler.buttons) {
         this.buttons = handler.buttons;
      }
      if (handler.body) {
         this.setBody(handler.body);
      }
      if (handler.validators) {
         this.validators = handler.validators;
      }
      if (handler.asyncValidators) {
         this.asyncValidators = handler.asyncValidators;
      }
      this.text = handler.text;
      if (handler.errors) {
         this.errors = handler.errors;
      }
      this.changeSub = handler.change.asObservable().subscribe((data: ChangeType) => {
         if (data.validators) {
            this.validators = data.validators;
         }
         if (data.asyncValidators) {
            this.asyncValidators = data.asyncValidators;
         }
         if (data.errors) {
            this.errors = data.errors;
         }
         this.title = data.title ? data.title : this.title;
         this.text = data.text ? data.text : this.text;
         this.reqAlert = data.reqAlert ? data.reqAlert : this.reqAlert;
         if (data.body) {
            this.setBody(data.body);
         }
         this.buttons = data.buttons ? data.buttons : this.buttons;
      });
      this.config = {
         keyboard: handler.keyboard,
         ignoreBackdropClick: handler.ignoreBackdropClick,
         class: handler.classes ? handler.classes.join(' ') : null,
      };
      this.closeButton = handler.closeButton;
      this.output = handler.output;
   }

   @Output() button = new EventEmitter<Body>();

   obs: Observable<void>;

   title: string;

   buttons: IModalButton[];

   text: string;

   config: ModalOptions = {};

   closeButton: boolean;

   localTime: boolean;

   bsConfig: Partial<BsDatepickerConfig>;

   reqAlert: string;

   ControlType = ControlType;

   output: Subject<Body>;

   body: IModalBody[];

   modalForm = this.fb.group({ inputs: this.fb.array([]) });

   modalRef: BsModalRef;

   showSub: Subscription;

   shownSub: Subscription;

   hiddenSub: Subscription;

   changeSub: Subscription;

   invalidData: InvalidDataType = [];

   checkFocus = false;

   shown: boolean;

   getFieldValue = getFieldValue;

   get inputs(): UntypedFormArray {
      return this.modalForm.get('inputs') as UntypedFormArray;
   }

   translateField = (translate: string, fields: string[]): string => {
      if (translate && fields) {
         fields.forEach((value, index) => {
            translate = translate.replace(`$${String(index + 1)}`, value);
         });
      }
      return translate;
   };

   setBody(body: IModalBody[]): void {
      this.inputs.clear();
      this.body = [];
      body.forEach((control) => {
         if (control.type === ControlType.date || control.type === ControlType.dateTime) {
            if (!this.localTime) {
               const date: Date = new Date(control.value);
               control.value = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
            } else {
               control.value = new Date(control.value);
            }
            const dateControl = this.fb.control(
               { value: control.value, disabled: control.disabled },
               control.required ? Validators.required : null,
            );
            if (control.required) {
               AddInvalidControl(this.invalidData, dateControl, [
                  'required',
                  this.translateField(this.reqAlert, [control.placeHolder]),
               ]);
            }
            this.inputs.push(dateControl);
            this.body.push(control);

            if (control.type === ControlType.dateTime) {
               const timeControl = this.fb.control(
                  { value: control.value, disabled: control.disabled },
                  control.required ? Validators.required : null,
               );
               if (control.required) {
                  AddInvalidControl(this.invalidData, timeControl, [
                     'required',
                     this.translateField(this.reqAlert, [control.placeHolder]),
                  ]);
               }
               this.inputs.push(timeControl);
               this.body.push({
                  id: `${control.id}time`,
                  type: ControlType.null,
                  placeHolder: '',
                  value: timeControl.value,
                  required: control.required,
                  disabled: control.disabled,
                  label: '',
                  hidden: false,
               });
            }
         } else {
            if (control.type === ControlType.dateRange && Array.isArray(control.value)) {
               control.value.forEach((val) => {
                  if (!this.localTime) {
                     const date: Date = new Date(val);
                     val = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
                  } else {
                     val = new Date(val);
                  }
               });
            }
            const formControl = this.fb.control({
               value: control.value,
               disabled: control.disabled,
            });
            if (control.validators || control.asyncValidators) {
               formControl.setValidators(control.validators);
               formControl.setAsyncValidators(control.asyncValidators);
               (control.errors || []).forEach((error) => {
                  // tslint:disable-next-line: max-line-length
                  AddInvalidControl(this.invalidData, formControl, [
                     error[0],
                     this.translateField(error[1], [control.placeHolder].concat(error.splice(2))),
                  ]);
               });
            }
            if (control.required) {
               formControl.setValidators((control.validators || []).concat(Validators.required));
               AddInvalidControl(this.invalidData, formControl, [
                  'required',
                  this.translateField(this.reqAlert, [control.placeHolder]),
               ]);
            }
            this.inputs.push(formControl);
            this.body.push(control);
         }
      });
   }

   set validators(validators: ValidatorFn[]) {
      this.inputs.setValidators(validators);
   }

   set asyncValidators(asyncValidators: AsyncValidatorFn[]) {
      this.inputs.setAsyncValidators(asyncValidators);
   }

   set errors(errors: string[][]) {
      this.invalidData = [];
      errors.forEach((error) => {
         // tslint:disable-next-line: max-line-length
         AddInvalidControl(this.invalidData, this.inputs, [
            error[0],
            this.translateField(error[1], [''].concat(error.splice(2))),
         ]);
      });
   }

   ngOnInit(): void {
      this.showSub = this.obs.subscribe(() => {
         if (!this.shown) {
            this.shown = true;
            this.openModal(this.template);
         }
      });
      this.shownSub = this.modalService.onShown.subscribe(() => {
         this.checkFocus = true;
         this.inputs.controls.forEach((element) => {
            element.reset();
         });
      });
      this.hiddenSub = this.modalService.onHidden.subscribe(() => {
         this.shown = false;
      });
   }

   ngOnDestroy(): void {
      if (this.showSub) {
         this.showSub.unsubscribe();
      }
      if (this.shownSub) {
         this.shownSub.unsubscribe();
      }
      if (this.hiddenSub) {
         this.hiddenSub.unsubscribe();
      }
      if (this.changeSub) {
         this.changeSub.unsubscribe();
      }
   }

   ngAfterViewChecked(): void {
      if (this.checkFocus && this.shown) {
         if (
            this.body &&
            this.body.length > 0 &&
            this.body.some((elem) => document.getElementById(elem.id) !== null)
         ) {
            // eslint-disable-next-line no-restricted-syntax
            for (const body of this.body) {
               if (!body.disabled && !body.hidden && body.type !== ControlType.formInline) {
                  document.getElementById(body.id).focus();
                  this.checkFocus = false;
                  return;
               }
            }
         }
         if (
            this.buttons &&
            this.buttons.length > 0 &&
            document.getElementById(this.buttons[0].id) != null
         ) {
            // eslint-disable-next-line no-restricted-syntax
            for (const button of this.buttons) {
               if (button.type === 'submit') {
                  document.getElementById(button.id).focus();
                  this.checkFocus = false;
               }
            }
         }
      }
   }

   openModal(template: TemplateRef<any>): void {
      this.modalRef = this.modalService.show(template, this.config);
   }

   onButton(event): void {
      this.modalRef.hide();
      const values: IElement[] = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [index, control] of this.body.entries()) {
         if (control.type !== ControlType.null) {
            let value = this.inputs.at(index).value;
            if (value instanceof Date) {
               if (!this.localTime) {
                  value = new Date(value.getTime() - value.getTimezoneOffset() * 60 * 1000);
               }
               if (control.type === ControlType.dateTime) {
                  const time = this.inputs.at(index + 1).value as Date;
                  value.setUTCHours(time.getHours());
                  value.setUTCMinutes(time.getMinutes());
                  value.setUTCSeconds(time.getSeconds());
               }
               value = value.toISOString();
            }
            if (control.type === ControlType.dateRange) {
               value = [value[0].toISOString(), value[1].toISOString()];
            }
            if (control.type === ControlType.number) {
               value = Number(value).valueOf();
            } else if (control.type === ControlType.checkbox) {
               if (control.indeterminate) {
                  value = undefined;
               } else {
                  value = control.value;
               }
            } else if (control.type === ControlType.formInline) {
               value = control.value;
            } else if (control.type === ControlType.radios) {
               value = control.value;
            }
            values.push({ id: control.id, value, disabled: control.disabled });
         }
      }
      this.button.emit(new Body(event.currentTarget.id, values));
      this.output.next(new Body(event.currentTarget.id, values));
   }

   onClick(body: IModalBody): void {
      if (!body.value || body.value === '0000-01-01') {
         body.value = new Date(Date.now());
      }
   }

   onCheckBox(control: IModalBody): void {
      if (control.indeterminate !== undefined) {
         const indeterminate = !!(!control.value && !control.indeterminate);
         const checked = !!(!control.value && control.indeterminate);
         control.indeterminate = indeterminate;
         control.value = checked;
      } else {
         control.value = !control.value;
      }
   }

   onRadio(control: IModalBody, value: number): void {
      control.value = value;
   }

   isInvalid = (): boolean =>
      !!this.inputs.errors ||
      this.inputs.controls.some((control) => control.invalid && control.touched);
}
