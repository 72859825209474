export const routes = {
   session: '/mts/v3/session',
   system: '/mts/v1/system',
   migration: '/api/migration',
   emailConfirm: '/mts/v1/communication/emails',
   emailUnsubscribe: '/mts/v1/communication/emails',
   csv: '/mts/v1/csv',
   csvDownload: '/mts/v1/csv-download',
   download: '/mts/v1/communication/documents',
   docs: '/mts/v1/communication/documents',
   validationDocumentss: '/mts/v1/communication/validation-documents',
   news: '/mts/v1/communication/news',
   settings: '/mts/v1/system',
   companies: '/mts/v1/master-files/companies',
   divisions: '/mts/v1/master-files/divisions',
   users: '/mts/v1/master-files/users',
   statusReasons: 'mts/v1/turnover/status-reasons',
   sysadmin: {
      maintenances: {
         testpack: '/mts/v1/sysadmin/test-pack',
         statuses: '/mts/v1/sysadmin/system-status',
         systemlog: '/mts/v1/statistics/system-log',
         users: {
            divisions: '/mts/v1/master-files/rights',
            rights: '/mts/v1/master-files/rights',
            manager: '/mts/v1/master-files/managers',
         },
         auditlog: '/mts/v1/statistics/audit-log',
         dictionary: '/mts/v1/sysadmin/dictionary',
         scheduledMaintenance: '/mts/v1/sysadmin/scheduled-maintenance',
         applicationVersion: '/mts/v1/sysadmin/app-version',
         portalVersion: '/mts/v1/sysadmin/portal-version',
         resendRecords: '/mts/v3/sysadmin/resend-records',
      },
      masterfiles: {
         company: '/mts/v1/master-files/companies',
         division: '/mts/v1/master-files/divisions',
         certificates: '/mts/v1/master-files/certificates',
         solidsoftCredentials: '/mts/v3/master-files/solidsoft-credentials',
         certificatesDetails: '/mts/v1/master-files/certificates-details',
         certificateshistory: '/mts/v1/master-files/certificates',
         decommissionreasons: '/mts/v1/master-files/checkout-reasons',
         users: '/mts/v1/master-files/users',
         terminals: '/mts/v1/master-files/terminals',
         products: '/mts/v1/master-files/products',
         nmvserrors: '/mts/v1/master-files/error-codes',
         wholesalers: '/mts/v1/master-files/wholesalers',
         tasknames: '/mts/v2/master-files/tasknames',
         apiKeys: '/mts/v1/master-files/api-keys',
      },
      communications: {
         helpdesk: '/mts/v1/communication/helpdesk',
         contacts: '/mts/v1/communication/contacts',
         news: '/mts/v1/communication/news',
         newsDetails: '/mts/v3/communication/news-details',
         companyEmail: '/mts/v1/sysadmin/company-email',
         broadcasts: '/mts/v1/sysadmin/broadcasts',
         language: '/mts/v3/communication/language',
      },
      statistics: {
         postgres: '/mts/v1/statistics/postgres',
         transactionnumbers: '/mts/v1/statistics/transaction-numbers',
         recordnumbers: '/mts/v1/statistics/record-numbers',
         alerts: '/mts/v1/statistics/alerts',
         runtime: '/mts/v1/statistics/runtime',
         statuses: '/mts/v1/statistics/statuses',
         nmvs: '/mts/v1/statistics/nmvs',
      },
      search: {
         sessions: '/mts/v1/turnover/sessions',
         tasks: '/mts/v1/turnover/tasks',
         transactions: '/mts/v1/turnover/transactions',
         records: '/mts/v1/turnover/records-data',
         datamatrix: '/mts/v1/turnover/datamatrix',
         recordsdata: '/mts/v1/turnover/records-data',
         statusReasons: '/mts/v1/turnover/status-reasons',
         pm2Log: '/mts/v3/turnover/log',
      },
   },
   translation: '/mts/v1/communication/translation',
   translationExport: '/mts/v1/communication/translation-export',
   user: {
      division: '/mts/v1/master-files/divisions',
   },
   masterfiles: {
      managedusers: '/mts/v1/master-files/users',
      decommissionreasons: '/mts/v1/master-files/checkout-reasons',
      nmvserrors: '/mts/v1/master-files/error-codes',
      products: '/mts/v1/master-files/products',
      terminals: '/mts/v1/master-files/terminals',
      wholesalers: '/mts/v1/master-files/wholesalers',
      parameters: '/mts/v1/master-files/parameters',
      ipGuard: '/mts/v1/master-files/ip-guard',
   },
   admin: {
      nvmsPassword: '/mts/v1/admin/password',
      divisions: '/mts/v1/admin/divisions',
      masterfiles: {
         divisions: '/mts/v1/master-files/divisions',
         users: '/mts/v1/master-files/users',
         terminals: '/mts/v1/master-files/terminals',
         certificates: '/mts/v1/master-files/certificates',
         solidsoftCredentials: '/mts/v3/master-files/solidsoft-credentials',
         certificatesDetails: '/mts/v1/master-files/certificates-details',
         tasknames: '/mts/v2/master-files/tasknames',
         apiKeys: '/mts/v1/master-files/api-keys',
      },
      maintenances: {
         userdivisions: '/mts/v1/master-files/rights',
         userrights: '/mts/v1/master-files/rights',
         auditlog: '/mts/v1/statistics/audit-log',
         manager: '/mts/v1/master-files/managers',
      },
      communications: {
         helpdesk: '/mts/v1/communication/helpdesk',
         contacts: '/mts/v1/communication/contacts',
         news: '/mts/v1/communication/news',
      },
   },
   history: {
      users: '/mts/v1/history/users',
      rights: '/mts/v1/history/rights',
      managers: '/mts/v1/history/managers',
   },
   statistics: {
      transactionnumbers: '/mts/v1/statistics/transaction-numbers',
      recordnumbers: '/mts/v1/statistics/record-numbers',
      runtime: '/mts/v1/statistics/runtime',
      alerts: '/mts/v1/statistics/alerts',
      statuses: '/mts/v1/statistics/statuses',
      nmvs: '/mts/v1/statistics/nmvs',
      batch: '/mts/v1/statistics/batch',
      recordProctTimes: '/mts/v3/sysadmin/record-proc-time',
   },
   search: {
      sessions: '/mts/v1/turnover/sessions',
      datamatrix: '/mts/v1/turnover/datamatrix',
      tasks: '/mts/v1/turnover/tasks',
      records: '/mts/v1/turnover/records-data',
      transactions: '/mts/v1/turnover/transactions',
      solidsoftTransactions: '/mts/v3/turnover/solidsoft-transactions',
      transactionDetails: '/mts/v1/turnover/transactions-details',
      recordsdata: '/mts/v1/turnover/records-data',
      alertDetails: '/mts/v1/nams',
      archiveTasks: '/mts/v1/turnover/archive-tasks',
   },
};
