import { NgModule } from '@angular/core';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { MainModule } from '../main/main.module';
import { DisplayJsonComponent } from './display-json/display-json.component';
import { UserRightsAccordionComponent } from './user-rights-accordion/user-rights-accordion.component';

const SHARED_COMPONENTS = [DisplayJsonComponent, UserRightsAccordionComponent];

@NgModule({
   imports: [MainModule, AccordionModule, PaginationModule.forRoot()],
   declarations: [SHARED_COMPONENTS],
   exports: [SHARED_COMPONENTS],
})
export class SharedModule {}
