<form [formGroup]="maintenance" scheduledMaintenanceValidator>
    <div class="card border-dark">
        <div class="card-header" (click)="changeHide()">
            <form class="form-inline">
                <b>{{ 'Schedule maintenance' | translate }}</b>
                <div class="col" style="text-align: right">
                    <lib-icon *ngIf="hideCardBody" [content]="icons.chevronDown"></lib-icon>
                    <lib-icon *ngIf="!hideCardBody" [content]="icons.chevronUp"></lib-icon>
                </div>
            </form>
        </div>
        <div *ngIf="!hideCardBody" class="card-body">
            <div class="form-row">
                <h4 style="text-align: center; color: #ffcc00; width: 100%;">{{ "Use UTC time for the maintenance interval!" | translate}}</h4>
            </div>
            <div class="form-row">
                <h5>{{"Maintenance interval" | translate}}</h5>
            </div>
            <div hidden>
                <input id="id" type="text" class="form-control" formControlName="id"/>
            </div>
            <div class="form-row">
                <div class="form-column col-md-3">
                    <div class="p-1">
                        <label for="startDate">{{ 'Maintenance start date-time' | translate }}</label>
                        <input id="startDate" type="text" class="form-control" bsDatepicker [bsConfig]="bsConfig" triggers="click:click" formControlName="startDate"
                            placement="right" />
                    </div>
                    <div class="p-1">
                        <input class="form-control" id="startTime" formControlName="startTime" type="text" />
                    </div>
                </div>
                <div class="form-column col-md-3">
                    <div class="p-1">
                        <label for="endDate">{{ 'Maintenance end date-time' | translate }}</label>
                        <input id="endDate" type="text" class="form-control" bsDatepicker [bsConfig]="bsConfig" triggers="click:click" formControlName="endDate" placement="right" />
                    </div>
                    <div class="p-1">
                        <input class="form-control" id="endTime" formControlName="endTime" type="text" />
                    </div>
                </div>
            </div>
            <span layout="row">
                <hr flex />
            </span>
            <div class="form-row">
                <h5>{{"News data" | translate}}</h5>
            </div>
            <div class="form-row">
                <div class="form-column col-md-3">
                    <div class="p-1">
                        <label for="newsPublishDate">{{ 'News publish date-time' | translate }}</label>
                        <input id="newsPublishDate" type="text" class="form-control" bsDatepicker [bsConfig]="bsConfig" triggers="click:click" formControlName="newsPublishDate"
                            placement="right" />
                    </div>
                    <div class="p-1">
                        <input class="form-control" id="newsPublishTime" formControlName="newsPublishTime" type="text" />
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-6 md-form form-group">
                    <label for="newsTitle">{{ 'News title in English' | translate }}</label>
                    <input id="newsTitle" class="form-control" type="text" formControlName="newsTitle">
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-6 md-form form-group">
                    <label for="newsBody">{{ 'News body in English' | translate }} ENG</label>
                    <textarea id="newsBody" class="form-control" type="text" formControlName="newsBody">
                    </textarea>
                </div>
            </div>
            <span layout="row">
                <hr flex />
            </span>
            <div class="form-row">
                <h5>{{"Broadcast data" | translate}}</h5>
            </div>
            <div class="form-row">
                <div class="form-column col-md-3">
                    <div class="p-1">
                        <label for="broadcastStartDate">{{ 'Broadcast validity start date-time' | translate }}</label>
                        <input id="broadcastStartDate" type="text" class="form-control" bsDatepicker [bsConfig]="bsConfig" triggers="click:click"
                            formControlName="broadcastStartDate" placement="right" />
                    </div>
                    <div class="p-1">
                        <input class="form-control" id="broadcastStartTime" formControlName="broadcastStartTime" type="text"/>
                    </div>
                </div>
                <div class="form-column col-md-3">
                    <div class="p-1">
                        <label for="broadcastEndDate">{{ 'Broadcast validity end date-time' | translate }}</label>
                        <input id="broadcastEndDate" type="text" class="form-control" bsDatepicker [bsConfig]="bsConfig" triggers="click:click"
                            formControlName="broadcastEndDate" placement="right" />
                    </div>
                    <div class="p-1">
                        <input class="form-control" id="broadcastEndTime" formControlName="broadcastEndTime" type="text" />
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-6 md-form form-group">
                    <b><label for="broadcastEng">{{ 'ENG' | translate }}</label></b>
                    <textarea id="broadcastEng" class="form-control" type="text" formControlName="broadcastEng">
                    </textarea>
                </div>
                <div class="col-md-6 md-form form-group">
                    <b><label for="broadcastHun">{{ 'HUN' | translate }}</label></b>
                    <textarea id="broadcastHun" class="form-control" type="text" formControlName="broadcastHun">
                    </textarea>
                </div>
                <div class="col-md-6 md-form form-group">
                    <b><label for="broadcastPol">{{ 'POL' | translate }}</label></b>
                    <textarea id="broadcastPol" class="form-control" type="text" formControlName="broadcastPol">
                    </textarea>
                </div>
                <div class="col-md-6 md-form form-group">
                    <b><label for="broadcastRon">{{ 'RON' | translate }}</label></b>
                    <textarea id="broadcastRon" class="form-control" type="text" formControlName="broadcastRon">
                    </textarea>
                </div>
            </div>
            <span layout="row">
                <hr flex />
            </span>
            <div class="form-row">
                <div class="p-1">
                    <button type="submit" class="btn btn-primary" (click)="onSubmit()" [disabled]="!isFormValid()">
                        {{ 'Submit' | translate }}
                    </button>
                </div>
                <div class="p-1">
                    <button type="button" class="btn btn-danger" (click)="onReset()">
                        {{ 'Cancel' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
<lib-table [label]="'Scheduled maintenances' | translate" [headers]="headers" [codes]="codes" [rows]="maintenances" [pagination]="pagination"
    (buttonEvent)="onButtonClick($event)" (pageChanged)="onPageChanged($event)">
</lib-table>
<lib-modal [handler]="confirmHandler" (button)="onRemoveConfirmed($event)"></lib-modal>