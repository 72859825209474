import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { paths } from './config/paths.config';
import { CsvDownloadComponent } from './csv-download/csv-download.component';
import { DownloadAppComponent } from './dowload-app/dowload-app.component';
import { DownloadReleaseComponent } from './download-release/download-release.component';
import { EmailConfirmComponent } from './email-confirm/email-confirm.component';
import { EmailUnsubsribeComponent } from './email-unsubsribe/email-unsubsribe.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ReloadComponent } from './reload/reload.component';
import { AdminActivateChildService } from './services/admin-activate-child.service';
import { CanActivateService } from './services/can-activate.service';
import { SysadminActivateChildService } from './services/sysadmin-activate-child.service';
import { SettingsComponent } from './settings/settings.component';
import { ResendRecordsComponent } from './sysadmin/maintenance/resend-records/resend-records.component';
import { ScheduledMaintenanceComponent } from './sysadmin/maintenance/scheduled-maintenance/scheduled-maintenance.component';

const routes: Routes = [
   { path: paths.login, component: LoginComponent },
   { path: paths.maintenance, component: MaintenanceComponent },
   { path: paths.home, component: HomeComponent, canActivate: [CanActivateService] },
   { path: paths.csvDownload, component: CsvDownloadComponent, canActivate: [CanActivateService] },
   { path: paths.reload, component: ReloadComponent, canActivate: [CanActivateService] },
   {
      path: paths.download, children: [
         { path: '**', component: DownloadAppComponent, canActivate: [CanActivateService] },
      ], component: DownloadAppComponent, canActivate: [CanActivateService],
   },
   { path: paths.emailConfirm, component: EmailConfirmComponent },
   { path: paths.emailUnsubscribe, component: EmailUnsubsribeComponent },
   {
      path: paths.sysadmin,
      loadChildren: () => import('./sysadmin/sysadmin.module').then((m) => m.SysadminModule),
      canActivate: [CanActivateService],
      canActivateChild: [SysadminActivateChildService],
   },
   {
      path: paths.admin,
      loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
      canActivate: [CanActivateService],
      canActivateChild: [AdminActivateChildService],
   },
   {
      path: paths.masterFile,
      loadChildren: () =>
         import('./master-files/master-files.module').then((m) => m.MasterFilesModule),
      canActivate: [CanActivateService],
   },
   {
      path: paths.search,
      loadChildren: () => import('./search/search.module').then((m) => m.SearchModule),
      canActivate: [CanActivateService],
   },
   {
      path: paths.statistic,
      loadChildren: () => import('./statistics/statistics.module').then((m) => m.StatisticsModule),
      canActivate: [CanActivateService],
   },
   {
      path: paths.history,
      loadChildren: () => import('./history/history.module').then((m) => m.HistoryModule),
      canActivate: [CanActivateService],
   },
   { path: paths.settings, component: SettingsComponent, canActivate: [CanActivateService] },
   { path: paths.downloadRelease, component: DownloadReleaseComponent, canActivate: [CanActivateService] },
   { path: paths.scheduledMaintenance, component: ScheduledMaintenanceComponent, canActivate: [CanActivateService] },
   { path: paths.resendRecords, component: ResendRecordsComponent, canActivate: [CanActivateService] },
   { path: '**', component: NotFoundComponent },
];

@NgModule({
   imports: [
      RouterModule.forRoot(routes, {
         onSameUrlNavigation: 'reload',
         relativeLinkResolution: 'legacy',
      }),
   ],
   exports: [RouterModule],
})
export class AppRoutingModule {}
