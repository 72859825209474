import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { mtsLoginLogo } from '../config/app.config';
import { routes } from '../config/routes.config';
import { Icons } from '../handlers/icon.handler';
import { HttpService } from '../main/http-service';

@Component({
   selector: 'app-maintenance',
   templateUrl: './maintenance.component.html',
   styleUrls: ['./maintenance.component.css'],
})
export class MaintenanceComponent {
   icons = Icons;

   mtsLogo = mtsLoginLogo;

   service: HttpService;

   constructor(public route: ActivatedRoute, public http: HttpClient, public router: Router) {
      this.service = new HttpService(this.http, routes.session);
      const interval = setInterval(() => {
         AppComponent.session = undefined;
         this.service.get({}, (res) => {
            if(res.status === 403) {
               AppComponent.logoutEvent.next();
               clearInterval(interval);
            } 
         });
      }, 60_000);
   }
   
}
