/* eslint-disable import/no-extraneous-dependencies */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule as NgxModal } from 'ngx-bootstrap/modal';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { LocaleModule } from './locale.module';
import { ModalComponent } from './modal.component';
import { IndeterminateDirective } from './indeterminate.directive';
import { AlertModule } from '../alert/alert.module';

@NgModule({
   declarations: [ModalComponent, IndeterminateDirective],
   imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      NgxModal.forRoot(),
      BsDropdownModule.forRoot(),
      BsDatepickerModule.forRoot(),
      TimepickerModule.forRoot(),
      AlertModule,
      LocaleModule,
   ],
   exports: [ModalComponent],
})
export class ModalModule {}
