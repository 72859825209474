<nav
   *ngIf="handler"
   class="navbar sticky-top navbar-expand-custom navbar-dark justify-content-end"
   [ngClass]="[handler.header.classes ? handler.header.classes.join(' ') : 'bg-primary']"
>
   <img
      *ngIf="handler.header.image"
      [src]="handler.header.image.src"
      [alt]="handler.header.image.alt"
      [ngStyle]="handler.header.image.style"
      [routerLink]="handler.header.image.link"
      style="cursor: pointer"
   />
   <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
   >
      <span class="navbar-toggler-icon"></span>
   </button>
   <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <a *ngIf="handler.header.text" class="navbar-brand" [routerLink]="['/']">{{
         handler.header.text
      }}</a>
      <div *ngFor="let nav of handler.navs" class="btn-group" dropdown>
         <button
            *ngIf="nav"
            id="button-basic"
            dropdownToggle
            type="button"
            class="btn"
            [ngClass]="[
               nav.disabled ? 'disabled' : '',
               nav.submenus ? 'dropdown-toggle' : '',
               nav.classes ? nav.classes.join(' ') : 'btn-primary'
            ]"
            aria-controls="dropdown-basic"
            [routerLink]="nav.link"
            [queryParamsHandling]="!nav.link || nav.link.length === 0 ? 'preserve' : 'default'"
            [tooltip]="nav.tooltip"
         >
            {{ nav.text }}
            <ng-container *ngIf="isIcons([nav.icon])">
               <lib-icon [content]="nav.icon.content" [libTooltip]="nav.icon.tooltip"></lib-icon>
            </ng-container>
         </button>
         <lib-submenu [parent]="nav" (button)="onButton($event)"></lib-submenu>
      </div>
      <div class="btn-group ml-auto">
         <ng-container *ngFor="let button of handler.buttons">
            <button
               *ngIf="button"
               [id]="button.id"
               class="btn"
               [ngClass]="[button.classes ? button.classes.join(' ') : 'btn-primary']"
               type="button"
               [tooltip]="button.tooltip"
               [disabled]="button.disabled"
               (click)="onButton(button.id)"
            >
               {{ button.text }}
               <ng-container *ngIf="isIcons([button.icon])">
                  <lib-icon
                     [content]="button.icon.content"
                     [libTooltip]="button.icon.tooltip"
                  ></lib-icon>
               </ng-container>
            </button>
         </ng-container>
      </div>
   </div>
</nav>
