/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
   Component,
   ElementRef,
   EventEmitter,
   Input,
   OnChanges,
   OnDestroy,
   OnInit,
   Output,
   ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { getFieldValue } from '../table/table.handler';
import { ElemList } from './select.handler';
import { SelectValidators } from './select.validator';

@Component({
   selector: 'lib-select',
   templateUrl: './select.component.html',
   styleUrls: ['./select.component.css'],
})
export class SelectComponent implements OnInit, OnDestroy, OnChanges {
   @ViewChild('select', { static: true }) selectRef: ElementRef;

   @Input() reset: Observable<void>;

   @Input() load: Observable<number>;

   @Input() unload: Observable<void>;

   @Input() disable: Observable<boolean>;

   @Input() header: string;

   @Input() options: string[];

   @Input() field: string;

   @Input() itemActiveField: string;

   @Input() required: boolean;

   @Input() elemList: ElemList;

   @Input() myName: string;

   @Input() myGroup: UntypedFormGroup;

   @Input() chooseStr: string;

   @Output() changed = new EventEmitter();

   private resetSub: Subscription;

   private loadSub: Subscription;

   private unloadSub: Subscription;

   private disableSub: Subscription;

   control = new UntypedFormControl({ value: '', disabled: true });

   loaded = false;

   getFieldValue = getFieldValue;

   ngOnInit(): void {
      this.control.setValue(-1);

      this.elemList.push({ name: this.myName, control: this.control, element: this.selectRef });
      this.myGroup.addControl(this.myName, this.control);

      if (this.reset) {
         this.resetSub = this.reset.subscribe(() => {
            this.control.reset();
            this.control.setValue(-1);
         });
      }
      if (this.load) {
         this.loadSub = this.load.subscribe((value) => {
            this.loaded = true;
            this.control.enable();
            if (!this.setFirstIfPossible()) {
               this.control.setValue(value || this.control.value);
            }
         });
      }
      if (this.unload) {
         this.unloadSub = this.unload.subscribe(() => {
            this.loaded = false;
            this.control.disable();
            this.control.setValue(-1);
         });
      }
      if (this.disable) {
         this.disableSub = this.disable.subscribe((disable) => {
            if (disable) {
               this.control.disable();
            } else {
               this.control.enable();
            }
         });
      }
   }

   ngOnDestroy(): void {
      this.elemList.splice(
         this.elemList.findIndex((elem) => elem.name === this.myName),
         1,
      );
      this.myGroup.removeControl(this.myName);
      if (this.reset) {
         this.resetSub.unsubscribe();
      }
      if (this.load) {
         this.loadSub.unsubscribe();
      }
      if (this.unload) {
         this.unloadSub.unsubscribe();
      }
      if (this.disable) {
         this.disableSub.unsubscribe();
      }
   }

   ngOnChanges(): void {
      // eslint-disable-next-line prefer-template
      this.setFirstIfPossible();
      if (this.required) {
         this.control.setValidators(SelectValidators.cannotBeEmpty);
      } else {
         this.control.clearValidators();
         this.control.updateValueAndValidity();
      }
   }

   onChange(value: any): void {
      this.changed.emit(value);
   }

   setFirstIfPossible(): boolean {
      if (this.options && this.options.length === 1 && this.control.value !== 0) {
         // eslint-disable-next-line no-underscore-dangle
         const value = (this.options[0] as any)._id || 0;
         this.control.setValue(value);
         this.onChange(value);
         return true;
      }
      return false;
   }
}
