import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { NgxSpinnerService } from 'ngx-spinner';
import publicIp from 'public-ip';
import { Subject, Subscription } from 'rxjs';
// eslint-disable-next-line max-len
import { take } from 'rxjs/operators';
import {
   httpCodes,
   HttpService,
   internalErrorHandler,
   isResultValid,
   localeType,
   Redirect,
   reloadHandler,
   timeoutHandler,
   translate,
   TranslateHandler,
   TranslatePipe,
   unauthorizedHandler,
} from 'src/app/main/http-service';
import { Body } from 'src/app/main/modal/body.handler';
import { Locales } from 'src/app/main/modal/locale.module';
import { ModalHandler } from 'src/app/main/modal/modal.handler';
import { translateModal } from 'src/app/main/modal/modal.translate';
import { environment } from 'src/environments/environment';
import { consentOkColor, langLocales, spinnerColor } from './config/app.config';
import { getCsvMenus } from './config/csv.options.config';
import { paths } from './config/paths.config';
import { routes } from './config/routes.config';
import { chooseLanguageHandler } from './handlers/choose.language.handler';
import { documentHandler } from './handlers/docs.handler';
import { optionsKeys } from './handlers/download.application.handler';
import { generateListsHandler } from './handlers/generate.lists.handler';
import { Handler, initNavbar } from './handlers/navbar.handler';
import { broadcastHandler } from './main/http-service/handlers/broadcast.handler';
import { ipErrorHandler } from './main/http-service/handlers/ip-error.handler';
import { ModalComponent } from './main/modal/modal.component';
import { IRights, ISession } from './medtracs-entities/session/static.session.get.entity';

export interface IPostSession {
   userId: string;
   password: string;
   version: string;
   deviceId: string;
   termName: string;
   timeZone?: string;
   timeZoneOffset?: number;
   ipv4?: string;
   ipv6?: string;
   forceLogin?: boolean;
}

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
   static spinner: NgxSpinnerService;

   static isFirstRefresh = true;

   static router: Router;

   static session: ISession;

   static loginEvent: Subject<IPostSession> = new Subject<IPostSession>();

   private static loginObservable = AppComponent.loginEvent.asObservable();

   static logoutEvent: Subject<void> = new Subject<void>();

   private static logoutObservable = AppComponent.logoutEvent.asObservable();

   static maintenanceEvent: Subject<void> = new Subject<void>();

   private static maintenanceObservable = AppComponent.maintenanceEvent.asObservable();

   static divisionChangeEvent: Subject<number> = new Subject<number>();

   private static divisionChangeObservable = AppComponent.divisionChangeEvent.asObservable();

   static invalidLoginEvent: Subject<void> = new Subject<void>();

   static invalidLoginObservable = AppComponent.invalidLoginEvent.asObservable();

   static tooManyLoginEvent: Subject<void> = new Subject<void>();

   static tooManyLoginObservable = AppComponent.tooManyLoginEvent.asObservable();

   static underMaintenanceEvent: Subject<void> = new Subject<void>();

   static underMaintenanceObservable = AppComponent.underMaintenanceEvent.asObservable();

   static timeoutHandler: ModalHandler;

   get timeoutHandler(): ModalHandler {
      return AppComponent.timeoutHandler;
   }

   static ipErrorHandler: ModalHandler;

   get ipErrorHandler(): ModalHandler {
      return AppComponent.ipErrorHandler;
   }

   static unauthorizedHandler: ModalHandler;

   get unauthorizedHandler(): ModalHandler {
      return AppComponent.unauthorizedHandler;
   }

   static internalErrorHandler: ModalHandler;

   get internalErrorHandler(): ModalHandler {
      return AppComponent.internalErrorHandler;
   }

   static errorHandler: ModalHandler;

   get errorHandler(): ModalHandler {
      return AppComponent.errorHandler;
   }

   static reloadHandler: ModalHandler;

   get reloadHandler(): ModalHandler {
      return AppComponent.reloadHandler;
   }

   broadcastHandler: ModalHandler;

   static chooseLanguageHandler: ModalHandler;

   get chooseLanguageHandler(): ModalHandler {
      return AppComponent.chooseLanguageHandler;
   }

   static documentHandler: ModalHandler;

   get documentHandler(): ModalHandler {
      return AppComponent.documentHandler;
   }

   static generateListsHandler: ModalHandler;

   get generateListsHandler(): ModalHandler {
      return AppComponent.generateListsHandler;
   }

   static navbarHandler: Handler;

   get navbarHandler(): Handler {
      return AppComponent.navbarHandler;
   }

   app = AppComponent;

   year = new Date().getFullYear();

   spinnerColor = spinnerColor;

   sessionService: HttpService;

   csvService: HttpService;

   downloadService: HttpService;

   documentService: HttpService;

   translateService: HttpService;

   routerSubscription: Subscription;

   queryParamMapSubscription: Subscription;

   loginSubscription: Subscription;

   logoutSubscription: Subscription;

   maintenaceSubscription: Subscription;

   divisionChangeSubscription: Subscription;

   redirect: Redirect;

   environment = environment;

   private translationChange: Subscription;

   broadcasts: string[] = [];

   @ViewChild('libDownload') dowloadModal: ModalComponent;

   constructor(
      private router: Router,
      private route: ActivatedRoute,
      private spinner: NgxSpinnerService,
      private http: HttpClient,
      private title: Title,
      private ccService: NgcCookieConsentService,
   ) {
      this.redirect = new Redirect(this.router, this.route);
      this.redirect.setDefaultUrl(paths.login);
      this.redirect.setReloadUrl(paths.reload);
      this.broadcastHandler = broadcastHandler();

      TranslateHandler.construct(Object.keys(Locales) as Array<localeType>);

      AppComponent.internalErrorHandler = internalErrorHandler();
      AppComponent.errorHandler = new ModalHandler();
      AppComponent.timeoutHandler = timeoutHandler();
      AppComponent.ipErrorHandler = ipErrorHandler();
      AppComponent.unauthorizedHandler = unauthorizedHandler();
      AppComponent.reloadHandler = reloadHandler();
      AppComponent.spinner = this.spinner;
      AppComponent.router = this.router;

      this.sessionService = new HttpService(this.http, routes.session);
      this.csvService = new HttpService(this.http, routes.csv, undefined, route);
      this.downloadService = new HttpService(this.http, routes.download);
      this.documentService = new HttpService(this.http, routes.docs);
      this.translateService = new HttpService(this.http, routes.translation);

      this.routerSubscription = this.router.events.subscribe((event) => {
         if (event instanceof NavigationEnd) {
            this.redirect.setUrlAfterRedirects(event.urlAfterRedirects);
            if(event.urlAfterRedirects.startsWith('/login?returnUrl=%2Flogin')) {
               this.router.navigate([paths.home]);  
            }
            if (this.navbarHandler) {
               this.navbarHandler.navbarHandler.buttons.find(
                  (button) => button.id === 'generate-lists',
               ).disabled = !getCsvMenus()
                  // eslint-disable-next-line no-underscore-dangle
                  .map((menu) => menu._id)
                  .some((id) => event.urlAfterRedirects.startsWith(id));
               if (this.router.url.startsWith('/download') && !this.router.url.includes('release')) {
                  const urlParts = this.router.url.split('/');
                  if (urlParts.length > 2 && optionsKeys.includes(urlParts[2])) {
                     this.dowloadModal.modalService.onShown.asObservable().pipe(take(1)).subscribe(() => {
                        setTimeout(() => {
                           this.dowloadModal.inputs.controls[0].setValue(urlParts[2]);
                        }, (100));

                     });
                  }

                  this.router.navigate([paths.home]);
               }
            }

         }
      });

      this.queryParamMapSubscription = this.route.queryParamMap.subscribe((queryParamMap) => {
         TranslateHandler.queryParamMap(queryParamMap);
      });

      this.loginSubscription = AppComponent.loginObservable.subscribe((credentials) => {
         this.sessionService.post<ISession>({ ...credentials, web: true }, async (res) => {
            if (isResultValid(res)) {
               if (this.redirect.getUrlAfterRedirects() === `/${this.redirect.getDefaultUrl()}`) {
                  this.redirect.setUrlAfterRedirects(undefined);
               }
               this.redirect.setDefaultUrl(paths.home);
               TranslatePipe.setLocale(langLocales[res.body.user.lang]);
               TranslateHandler.setLockedLocale();
               AppComponent.session = res.body;
               this.broadcasts = AppComponent.session.broadcasts.map((b) => b.value);
               this.onBroadcastButton();
               this.updateTitle(AppComponent.session.user.lastDiv);
               await this.translateSession(res);
            } else if (res.status === httpCodes.unauthorized) {
               AppComponent.invalidLoginEvent.next();
               AppComponent.session = undefined;
            } else if(res.status === httpCodes.paymentRequired) {
               AppComponent.tooManyLoginEvent.next();
               AppComponent.session = undefined;
            } else if(res.status === httpCodes.preconditionRequired) {
               AppComponent.underMaintenanceEvent.next();
               AppComponent.session = undefined;
            }
         });
      });

      this.divisionChangeSubscription = AppComponent.divisionChangeObservable.subscribe(
         async (divisionId) => {
            if (divisionId !== AppComponent.session.user.lastDiv && divisionId !== 0) {
               this.sessionService.put<{ right: IRights }>(
                  { divisionId, ipv4: await publicIp.v4() },
                  async (res) => {
                     if (isResultValid(res)) {
                        AppComponent.session.user.lastDiv = divisionId;
                        const rIdx = AppComponent.session.rights.findIndex(
                           (r) => r.divId === divisionId,
                        );
                        AppComponent.session.rights[rIdx] = res.body.right;
                        await this.redirect.reloadPage(() => {
                           AppComponent.navbarHandler = initNavbar();
                        });
                        this.updateTitle(divisionId);
                     }
                  },
               );
            } else if (divisionId === 0) {
               AppComponent.navbarHandler = initNavbar();
            }
         },
      );

      this.logoutSubscription = AppComponent.logoutObservable.subscribe(() => {
         // azért kell ide, hogy ha timeout is volt, ne írja ki feleslegesen az a hibát
         this.updateTitle(0);
         AppComponent.session = undefined;
         this.sessionService.delete<any>(undefined, {}, async (res) => {
            if (
               isResultValid(res) ||
               res.status === httpCodes.notFound ||
               res.status === httpCodes.forbidden
            ) {
               AppComponent.navbarHandler = undefined;
               this.redirect.setDefaultUrl(paths.login);
               await this.redirect.toDefaultUrl(undefined, false);
               this.redirect.setUrlAfterRedirects(undefined);
            }
         });
      });

      this.maintenaceSubscription = AppComponent.maintenanceObservable.subscribe(() => {
         this.router.navigate([paths.maintenance]);
      });

      AppComponent.chooseLanguageHandler = chooseLanguageHandler();
      AppComponent.generateListsHandler = generateListsHandler();
      AppComponent.documentHandler = documentHandler();
   }

   onBroadcastButton(): void {
      if (!this.broadcasts.length) {
         return;
      }
      setTimeout(() => {
         this.broadcastHandler.text = this.broadcasts.splice(0, 1)[0];
         this.broadcastHandler.change.next(translateModal(this.broadcastHandler, translate));
         this.broadcastHandler.event.next();
      }, 1000);
   }

   static isRight(right: keyof IRights): boolean {
      return (
         this.session &&
         this.session.rights &&
         Boolean(
            this.session.rights.find((r) => r.divId === AppComponent.session.user.lastDiv)[right],
         )
      );
   }

   static onUnauthorized(error: ISession, url: string): void {
      AppComponent.unauthorizedHandler.event.next();
      if (error && error.rights) {
         AppComponent.session.rights = error.rights;
      } else if (url && AppComponent.session && AppComponent.session.rights) {
         url.split('/').forEach((segment) => {
            if (
               AppComponent.session.rights.find(
                  (r) => r.divId === AppComponent.session.user.lastDiv,
               )[segment]
            ) {
               AppComponent.session.rights.find(
                  (r) => r.divId === AppComponent.session.user.lastDiv,
               )[segment] = false;
            }
         });
      }
   }

   static onIpError(): void {
      AppComponent.logoutEvent.next();
      AppComponent.ipErrorHandler.event.next();
   }

   static onMaintenance(): void {
      AppComponent.maintenanceEvent.next();
   }

   async onUnauthorizedClick(event: Body): Promise<void> {
      AppComponent.navbarHandler = initNavbar();
      await this.redirect.toDefaultUrl();
   }

   ngOnInit(): void {
      this.translationChange = TranslatePipe.changed.subscribe(() => {
         if (!this.ccService.hasConsented()) {
            this.ccService.getConfig().content = this.ccService.getConfig().content || {};
            this.ccService.getConfig().palette.button.background = consentOkColor;
            this.ccService.getConfig().content.message = translate(
               this.ccService.getConfig().content.message,
            );
            this.ccService.getConfig().content.dismiss = translate(
               this.ccService.getConfig().content.dismiss,
            );
            this.ccService.getConfig().content.link = translate(
               this.ccService.getConfig().content.link,
            );
            this.ccService.getConfig().content.href = `${routes.download
               }?code=privacypolicy&country=${Object.keys(langLocales).find(
                  (key) => langLocales[key] === TranslatePipe.getLocale(),
               )}`;
            this.ccService.destroy();
            this.ccService.init(this.ccService.getConfig());
            this.ccService.open();
         }
      });

      setTimeout(() => {
         this.sessionService.get<ISession>({}, async (res) => {
            if (isResultValid(res)) {
               // ha login az urlAfterRedirects, akkor ne vigye oda
               if (this.redirect.getUrlAfterRedirects() === `/${this.redirect.getDefaultUrl()}`) {
                  this.redirect.setUrlAfterRedirects(undefined);
               }
               this.redirect.setDefaultUrl(paths.home);
               if (!TranslateHandler.getLockedLocale()) {
                  TranslatePipe.setLocale(langLocales[res.body.user.lang]);
                  TranslateHandler.setLockedLocale();
               }
               AppComponent.session = res.body;
               this.updateTitle(AppComponent.session.user.lastDiv);
            } else if (res instanceof HttpErrorResponse) {
               this.redirect.setDefaultUrl(paths.login);
               AppComponent.session = res.error;
            }
            await this.translateSession(res);
         });
      }, 100);
   }

   ngOnDestroy(): void {
      this.routerSubscription.unsubscribe();
      this.queryParamMapSubscription.unsubscribe();
      this.loginSubscription.unsubscribe();
      this.logoutSubscription.unsubscribe();
      this.maintenaceSubscription.unsubscribe();
      this.divisionChangeSubscription.unsubscribe();
      this.translationChange.unsubscribe();
   }

   updateTitle(divisionId: number): void {
      const right = AppComponent.session && AppComponent.session.rights
         ? AppComponent.session.rights.find((r) => r.divId === divisionId)
         : undefined;
      this.title.setTitle(`MedTracS${right ? ` - ${right.divName}` : ''}`);
   }

   get division(): string {
      return this.app.session.rights.find((r) => r.divId === this.app.session.user.lastDiv).divName;
   }

   async translateSession(res: HttpResponse<ISession>): Promise<void> {
      await TranslateHandler.startSession(
         AppComponent,
         this,
         AppComponent.session.user ? undefined : true,
         async () => {
            this.translateModals();
            if (isResultValid(res) && res.body.rights && res.body.user) {
               AppComponent.navbarHandler = initNavbar();
               await this.redirect.toReturnUrl();
            }
         },
      );
   }

   translateModals(): void {
      AppComponent.timeoutHandler.change.next(translateModal(timeoutHandler(), translate));
      AppComponent.unauthorizedHandler.change.next(
         translateModal(unauthorizedHandler(), translate),
      );
      AppComponent.ipErrorHandler.change.next(translateModal(ipErrorHandler(), translate));
      AppComponent.internalErrorHandler.change.next(
         translateModal(internalErrorHandler(), translate),
      );
      AppComponent.reloadHandler.change.next(translateModal(reloadHandler(), translate));

      AppComponent.chooseLanguageHandler.change.next(
         translateModal(chooseLanguageHandler(), translate),
      );
      AppComponent.generateListsHandler.change.next(
         translateModal(generateListsHandler(), translate),
      );
      AppComponent.documentHandler.change.next(translateModal(documentHandler(), translate));
   }

   onButton(event: string): void | Promise<boolean> {
      switch (true) {
         case event.split(':')[0] === 'divisionId': {
            return AppComponent.divisionChangeEvent.next(Number(event.split(':')[1]));
         }
         case event === 'language': {
            return this.chooseLanguageHandler.event.next();
         }
         case event === 'settings': {
            return this.router.navigate([paths.settings]);
         }
         case event === 'info': {
            return this.documentHandler.event.next();
         }
         case event === 'logout': {
            return AppComponent.logoutEvent.next();
         }
         case event === 'generate-lists': {
            return this.router.navigate([paths.csvDownload], {
               queryParams: { ...this.csvService.getParams(), url: this.router.url.split('?')[0] },
            });
         }
         case event === 'download': {
            return this.router.navigate([paths.downloadRelease]);
         }
         default:
      }
      return null;
   }

   async onTimeout(): Promise<void> {
      this.redirect.setDefaultUrl(paths.login);
      await this.redirect.toDefaultUrl();
      AppComponent.session = undefined;
      AppComponent.navbarHandler = undefined;
   }

   onChooseLanguage(event: Body): void {
      if (event.getKey() === 'choose-language-ok') {
         const language = event.getField('choose-language');
         TranslatePipe.setLocale(Locales[language] as any);
         TranslateHandler.setLockedLocale();
         AppComponent.session.user.lang = Object.keys(langLocales).find(
            (key) => langLocales[key] === TranslatePipe.getLocale(),
         );
         this.sessionService.patch<any>(
            'language',
            { language: AppComponent.session.user.lang },
            async () => {
               await TranslateHandler.startSession(AppComponent, this, undefined, async () => {
                  AppComponent.navbarHandler = initNavbar();
                  this.translateModals();
               });
            },
         );
      }
   }

   onGenerateLists(event: Body): void {
      if (event.getKey() === 'generate-lists-ok') {
         this.csvService.download(
            {
               url: encodeURIComponent(event.getField('csv-options')),
               ...this.csvService.getParams(),
               from: 1,
               to: event.getField('rows') ? 10000 : undefined,
            },
            () => null,
         );
      }
   }

}
