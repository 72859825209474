import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import _ from 'lodash';
import {
   b64DecodeUnicode,
   HttpService,
   isResultValid,
   translate,
   TranslatePipe,
} from 'src/app/main/http-service';
import { AppComponent } from '../app.component';
import { routes } from '../config/routes.config';
import {
   center,
   Codes,
   bolder,
   convertToBool,
   convertDateToLocaleDate,
   Headers,
} from '../main/table/table.handler';
import { IOrderByEvent } from '../main/table/table.interface';
import { translateHeaders, translateRows } from '../main/table/table.translate';
import { IGetUserDivisions } from '../medtracs-entities/user-divisions/get.user.divisions.entity';

@Component({
   selector: 'app-home',
   templateUrl: './home.component.html',
   styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
   service: HttpService;

   newsService: HttpService;

   divisions: IGetUserDivisions[];

   news: { title: string; text: string; createDate: Date }[] = [];

   locale = TranslatePipe.getLocale();

   headers = new Headers([
      'Company name',
      'Division name',
      'Environment',
      'Division type',
      'Client ID',
      center('Color'),
      center('Admin'),
      center('Manager'),
   ]);

   codes = new Codes([
      'owner',
      bolder('divName'),
      bolder('environment'),
      'divType',
      bolder('client'),
      center('color'),
      center('admin'),
      center('manager'),
   ]);

   user = AppComponent.session.user.userId;

   division = AppComponent.session.rights.find(
      (div) => div.divId === AppComponent.session.user.lastDiv,
   ).divName;

   constructor(private http: HttpClient) {
      this.service = new HttpService(http, routes.user.division);
      this.newsService = new HttpService(this.http, routes.news);
      translateHeaders(this.headers, translate);
   }

   ngOnInit(): void {
      this.getNews();
      this.service.get<{ divisions: IGetUserDivisions[] }>(undefined, (res) => {
         if (isResultValid(res)) {
            this.divisions = res.body.divisions;
            if (this.divisions.length > 0) {
               translateRows(this.divisions, ['environment', 'divType'], translate);
               convertToBool(this.divisions, ['admin', 'manager']);
            }
         }
      });
   }

   getNews(): void {
      this.newsService.get<{ news: { title: string; text: string; createDate: Date }[] }>(
         {},
         (result) => {
            if (isResultValid(result)) {
               this.news = result.body.news;
               convertDateToLocaleDate(this.news, ['createDate'], this.locale);
               this.news.forEach((n) => (n.text = b64DecodeUnicode(n.text)));
            }
         },
      );
   }

   onOrderBy(event: IOrderByEvent): void {
      this.divisions = _.orderBy(this.divisions, event.orderBy, event.direction);
   }
}
