<div class="center">
   <div align="center">
      <img [src]="mtsLogo" alt="MedTracS" style="max-width: 50%" />
   </div>
   <br /><br />
   <div *ngIf="emailOk" align="center" style="color: green">
      <h1>{{ 'Email confirm is successful.' | translate }}</h1>
   </div>
   <div *ngIf="emailBad" align="center" style="color: red">
      <h1>{{ 'Email confirm is not successful!' | translate }}</h1>
   </div>
   <div *ngIf="emailConfirmed" align="center" style="color: red">
      <h1>{{ 'Email is already confirmed!' | translate }}</h1>
   </div>
</div>
