import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { NgxSpinnerModule } from 'ngx-spinner';
import {
   HttpLoadingInterceptorService,
   HttpNoCacheInterceptorService,
   HttpResponseInterceptorService,
   HttpVersionInterceptorService,
} from 'src/app/main/http-service';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import packageInfo from '../../package.json';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { cookieConfig } from './config/app.config';
import { CsvDownloadComponent } from './csv-download/csv-download.component';
import { DownloadAppComponent } from './dowload-app/dowload-app.component';
import { DownloadReleaseComponent } from './download-release/download-release.component';
import { EmailConfirmComponent } from './email-confirm/email-confirm.component';
import { EmailUnsubsribeComponent } from './email-unsubsribe/email-unsubsribe.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { MainModule } from './main/main.module';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ReloadComponent } from './reload/reload.component';
import { SettingsComponent } from './settings/settings.component';
import { SharedModule } from './shared/shared.module';
import { ResendRecordsComponent } from './sysadmin/maintenance/resend-records/resend-records.component';

@NgModule({
   declarations: [
      AppComponent,
      LoginComponent,
      HomeComponent,
      NotFoundComponent,
      SettingsComponent,
      ReloadComponent,
      DownloadAppComponent,
      EmailConfirmComponent,
      EmailUnsubsribeComponent,
      CsvDownloadComponent,
      DownloadReleaseComponent,
      MaintenanceComponent,
      ResendRecordsComponent,
   ],
   imports: [
      AppRoutingModule,
      BrowserAnimationsModule,
      HttpClientModule,
      NgxSpinnerModule,
      MainModule,
      SharedModule,
      NgcCookieConsentModule.forRoot(cookieConfig),
      TooltipModule.forRoot(),
      AccordionModule.forRoot(),
   ],
   providers: [
      {
         provide: HTTP_INTERCEPTORS,
         useFactory: () => new HttpLoadingInterceptorService(AppComponent.spinner),
         multi: true,
      },
      {
         provide: HTTP_INTERCEPTORS,
         useFactory: () => new HttpNoCacheInterceptorService(),
         multi: true,
      },
      {
         provide: HTTP_INTERCEPTORS,
         useFactory: () =>
            new HttpResponseInterceptorService(
               (right) => AppComponent.session !== undefined,
               AppComponent.timeoutHandler,
               AppComponent.onUnauthorized,
               AppComponent.internalErrorHandler,
               AppComponent.errorHandler,
               AppComponent.onIpError,
               AppComponent.onMaintenance,
               [
                  '/api/sysadmin/search/records-data?type=rsn',
                  '/api/search/records-data?type=rsn',
                  '/api/sysadmin/maintenances/users/divisions',
                  'api/admin/maintenances/user-divisions',
                  '/mts/v1/admin/password',
                  '/mts/v1/sysadmin/system-status',
               ],
               AppComponent.reloadHandler,
            ),
         multi: true,
      },
      {
         provide: HTTP_INTERCEPTORS,
         useFactory: () => new HttpVersionInterceptorService(packageInfo.version),
         multi: true,
      },
   ],
   bootstrap: [AppComponent],
})
export class AppModule {}
