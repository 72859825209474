import { Component, OnInit } from '@angular/core';
import { Icons } from '../../handlers/icon.handler';

@Component({
   selector: 'app-description',
   templateUrl: './description.component.html',
   styleUrls: ['./description.component.css'],
})
export class DescriptionComponent {
   hideCardBody = true;

   icons = Icons;
}
