/* eslint-disable no-useless-constructor */
import { Injectable } from '@angular/core';
import {
   CanActivate,
   ActivatedRouteSnapshot,
   RouterStateSnapshot,
   UrlTree,
   Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AppComponent } from '../app.component';
import { paths } from '../config/paths.config';

@Injectable({
   providedIn: 'root',
})
export class CanActivateService implements CanActivate {
   constructor(private router: Router) {}

   canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot,
   ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
      if (AppComponent.session) {
         return true;
      }
      this.router.navigate([paths.login], { queryParams: { returnUrl: state.url } });
      return false;
   }
}
