import { Directive, ElementRef, Input } from '@angular/core';

// tslint:disable-next-line: directive-selector
@Directive({ selector: '[indeterminate]' })
export class IndeterminateDirective {
   @Input()
   set indeterminate(value: boolean) {
      this.elem.nativeElement.indeterminate = value;
   }

   // eslint-disable-next-line no-useless-constructor
   constructor(private elem: ElementRef) {}
}
