<ng-container [ngSwitch]="true">
   <ng-container *ngSwitchCase="content.split(':')[0] === 'fas'">
      <fa-icon
         [icon]="fas[content.split(':')[1]]"
         size="lg"
         (click)="onClick()"
         tooltip="{{ libTooltip ? libTooltip : null }}"
         placement="{{ libTooltip ? 'auto' : null }}"
      >
      </fa-icon>
   </ng-container>
   <ng-container *ngSwitchCase="content.split(':')[0] === 'far'">
      <fa-icon
         [icon]="far[content.split(':')[1]]"
         size="lg"
         (click)="onClick()"
         tooltip="{{ libTooltip ? libTooltip : null }}"
         placement="{{ libTooltip ? 'auto' : null }}"
      >
      </fa-icon>
   </ng-container>
   <ng-container *ngSwitchDefault>
      {{ content }}
   </ng-container>
</ng-container>
