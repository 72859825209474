import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { routes } from 'src/app/config/routes.config';
import { HttpService, isResultValid, translate } from '../http-service';
import { ElemList } from '../select/select.handler';

@Component({
   selector: 'app-user-select',
   templateUrl: './user-select.component.html',
   styleUrls: ['./user-select.component.css'],
})
export class UserSelectComponent implements OnInit {
   elemList: ElemList = [];

   right = AppComponent.isRight('admin') ? 'ADMIN' : 'MANAGER';

   service: HttpService;

   users = [];

   @Input('form') form: UntypedFormGroup;

   @Input('chooseString') chooseString: UntypedFormGroup;

   loadUserEvent: Subject<void> = new Subject<void>();

   loadUserObs = this.loadUserEvent.asObservable();

   unloadUserEvent: Subject<void> = new Subject<void>();

   unloadUserObs = this.unloadUserEvent.asObservable();

   constructor(private http: HttpClient) {
      this.service = new HttpService(http, routes.users);
   }

   ngOnInit(): void {
      this.service.get<any>({ skip: 0, limit: 200000, right: this.right }, (res) => {
         if (isResultValid(res)) {
            this.users = [{ name: translate('All'), userId: undefined }];
            res.body.users.forEach((r) => {
               this.users.push({ name: r.userId, userId: r.userId });
            });
            this.loadUserEvent.next();
         }
      });
   }

   onChange(event: any): void {
      this.form.addControl('user', new UntypedFormControl());
      this.form.get('user').setValue(this.users[event].userId);
   }
}
