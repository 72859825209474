import { translate } from 'src/app/main/http-service';
import { AppComponent } from '../app.component';
import { bgStyle, btnStyle, mtsLogo } from '../config/app.config';
import { paths } from '../config/paths.config';
import { IconClass } from '../main/icon/icon.handler';
import { IButton, IDropdown, NavbarHandler } from '../main/navbar/navbar.handler';
import { translateButtons, translateDropdown } from '../main/navbar/navbar.translate';
import { Icons } from './icon.handler';
import { ProviderEnum } from '../medtracs-entities/session/static.session.get.entity';

export class Handler {
   private btn = btnStyle;

   private sysBtn = ['nav-sys-btn'];

   private superBtn = ['nav-super-btn'];

   private classes = bgStyle;

   private sysadmin = this.btn.concat(['nav-sys-btn']);

   private selectedBtn = ['nav-selected'];

   public navbarHandler: NavbarHandler;

   constructor() {
      this.navbarHandler = new NavbarHandler({
         image: {
            src: mtsLogo,
            alt: 'MedTracS',
            style: { height: '34px', width: '231px', 'object-fit': 'contain' },
            link: [paths.home],
         },
         classes: this.classes,
         text: '',
      });
   }

   navDivision(): void {
      let submenus: IDropdown[] = [{ text: translate('Home'), link: [paths.home], divider: true }];
      const divisions = AppComponent.session.rights.map<IDropdown>((right) => ({
         text: right.divName,
         id: `divisionId:${String(right.divId)}`,
         onclick: true,
         classes: AppComponent.session.user.lastDiv === right.divId ? this.selectedBtn : [],
      }));
      submenus = submenus.concat(divisions);
      const dropdown: IDropdown = {
         icon: new IconClass(Icons.home),
         text: '',
         classes: this.btn,
         tooltip: translate('Divisions'),
         submenus,
      };
      this.navbarHandler.addNav(dropdown);
   }

   navSysadmin(superAdmin: boolean): void {
      const dropdown: IDropdown = {
         text: 'SYSADMIN',
         classes: this.sysadmin,
         submenus: [
            {
               text: 'Master files',
               classes: this.sysBtn,
               divider: true,
               submenus: [
                  {
                     text: 'Companies',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.masterFile,
                        paths.sysadmins.masterFiles.companies,
                     ],
                  },
                  {
                     text: 'Divisions',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.masterFile,
                        paths.sysadmins.masterFiles.divisions,
                     ],
                  },
                  {
                     text: 'Certificates',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.masterFile,
                        paths.sysadmins.masterFiles.certificates,
                     ],
                  },
                  {
                     text: 'SolidSoft Credentials',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.masterFile,
                        paths.sysadmins.masterFiles.solidsoftCredentials,
                     ],
                  },
                  {
                     text: 'Users',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.masterFile,
                        paths.sysadmins.masterFiles.users,
                     ],
                  },
                  {
                     text: 'Terminals',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.masterFile,
                        paths.sysadmins.masterFiles.terminals,
                     ],
                  },
                  {
                     text: 'Task names',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.masterFile,
                        paths.sysadmins.masterFiles.taskNames,
                     ],
                  },
                  {
                     text: 'Products',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.masterFile,
                        paths.sysadmins.masterFiles.products,
                     ],
                  },
                  {
                     text: 'Wholesalers',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.masterFile,
                        paths.sysadmins.masterFiles.wholesalers,
                     ],
                  },
                  {
                     text: 'Decommission reasons',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.masterFile,
                        paths.sysadmins.masterFiles.decommissionReasons,
                     ],
                  },
                  {
                     text: 'NMVS errors',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.masterFile,
                        paths.sysadmins.masterFiles.nmvsErrors,
                     ],
                  },
                  {
                     text: 'API keys',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.masterFile,
                        paths.sysadmins.masterFiles.apiKeys,
                     ],
                  },
                  {
                     text: 'IP guard',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.masterFile,
                        paths.sysadmins.masterFiles.ipGuard,
                     ],
                  },
               ],
            },
            {
               text: 'Maintenance',
               classes: this.sysBtn,
               divider: true,
               submenus: [
                  {
                     text: 'Development',
                     classes: this.superBtn,
                     divider: true,
                     disabled: !superAdmin,
                     submenus: [
                        {
                           text: 'Migration',
                           classes: this.superBtn,
                           disabled: !superAdmin,
                           link: [
                              paths.sysadmin,
                              paths.sysadmins.maintenance,
                              paths.sysadmins.maintenances.development,
                              paths.sysadmins.maintenances.developments.migration,
                           ],
                        },
                        {
                           text: 'Baseline testing',
                           classes: this.superBtn,
                           link: [],
                           disabled: !superAdmin,
                        },
                        {
                           text: 'Parameters',
                           classes: this.superBtn,
                           link: [
                              paths.sysadmin,
                              paths.sysadmins.maintenance,
                              paths.sysadmins.maintenances.parameter,
                           ],
                           disabled: !superAdmin,
                        },
                        {
                           text: 'System locks',
                           classes: this.superBtn,
                           link: [],
                           disabled: !superAdmin,
                        },
                        {
                           text: 'Application versions',
                           classes: this.superBtn,
                           link: [
                              paths.sysadmin,
                              paths.sysadmins.maintenance,
                              paths.sysadmins.maintenances.development,
                              paths.sysadmins.maintenances.developments.applicationVersion,
                           ],
                           disabled: !superAdmin,
                        },
                        {
                           text: 'Portal versions',
                           classes: this.superBtn,
                           link: [
                              paths.sysadmin,
                              paths.sysadmins.maintenance,
                              paths.sysadmins.maintenances.development,
                              paths.sysadmins.maintenances.developments.portalVersions,
                           ],
                           disabled: !superAdmin,
                        },
                        {
                           text: 'Validation documents',
                           classes: this.superBtn,
                           link: [
                              paths.sysadmin,
                              paths.sysadmins.maintenance,
                              paths.sysadmins.maintenances.development,
                              paths.sysadmins.maintenances.developments.validationDocuments,
                           ],
                           disabled: !superAdmin,
                        },
                     ],
                  },
                  {
                     text: 'Users',
                     classes: this.sysBtn,
                     divider: true,
                     submenus: [
                        {
                           text: 'User divisions',
                           classes: this.sysBtn,
                           link: [
                              paths.sysadmin,
                              paths.sysadmins.maintenance,
                              paths.sysadmins.maintenances.user,
                              paths.sysadmins.maintenances.users.divisions,
                           ],
                        },
                        {
                           text: 'User rights',
                           classes: this.sysBtn,
                           link: [
                              paths.sysadmin,
                              paths.sysadmins.maintenance,
                              paths.sysadmins.maintenances.user,
                              paths.sysadmins.maintenances.users.rights,
                           ],
                        },
                        {
                           text: 'Manager settings',
                           classes: this.sysBtn,
                           link: [
                              paths.sysadmin,
                              paths.sysadmins.maintenance,
                              paths.sysadmins.maintenances.user,
                              paths.sysadmins.maintenances.users.manager,
                           ],
                        },
                     ],
                  },
                  {
                     text: 'Translation',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.maintenance,
                        paths.sysadmins.maintenances.translation,
                     ],
                  },
                  {
                     text: 'Dictionary',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.maintenance,
                        paths.sysadmins.maintenances.dictionary,
                     ],
                  },
                  {
                     text: 'System logs',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.maintenance,
                        paths.sysadmins.maintenances.systemLog,
                     ],
                  },
                  {
                     text: 'Audit logs',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.maintenance,
                        paths.sysadmins.maintenances.auditLog,
                     ],
                  },
                  {
                     text: 'Generate test pack',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.maintenance,
                        paths.sysadmins.maintenances.testpack,
                     ],
                  },
                  {
                     text: 'Server modules',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.maintenance,
                        paths.sysadmins.maintenances.statuses,
                     ],
                  },
                  {
                     text: 'PM2 logs',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.maintenance,
                        paths.sysadmins.maintenances.pm2log,
                     ],
                  },
               ],
            },
            {
               text: 'Communication',
               classes: this.sysBtn,
               divider: true,
               submenus: [
                  {
                     text: 'Contacts',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.communication,
                        paths.sysadmins.communications.contacts,
                     ],
                  },
                  {
                     text: 'News and upgrades',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.communication,
                        paths.sysadmins.communications.news,
                     ],
                  },
                  {
                     text: 'Helpdesk',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.communication,
                        paths.sysadmins.communications.helpdesk,
                     ],
                  },
                  {
                     text: 'Company email',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.communication,
                        paths.sysadmins.communications.companyEmail,
                     ],
                  },
                  {
                     text: 'Broadcasts',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.communication,
                        paths.sysadmins.communications.broadcasts,
                     ],
                  },
               ],
            },
            {
               text: 'Data queries',
               classes: this.sysBtn,
               divider: true,
               submenus: [
                  {
                     text: 'Sessions',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.search,
                        paths.sysadmins.searches.sessions,
                     ],
                  },
                  {
                     text: 'Tasks',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.search,
                        paths.sysadmins.searches.tasks,
                     ],
                  },
                  {
                     text: 'Records',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.search,
                        paths.sysadmins.searches.recordsData,
                     ],
                  },
                  {
                     text: 'Transactions',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.search,
                        paths.sysadmins.searches.transactions,
                     ],
                  },
                  {
                     text: 'Solidsoft transactions',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.search,
                        paths.sysadmins.searches.solidsoftTransactions,
                     ],
                  },
                  {
                     text: 'Alerts',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.search,
                        paths.sysadmins.searches.alerts,
                     ],
                  },
                  {
                     text: 'Aggregated codes',
                     classes: this.superBtn,
                     link: [],
                     disabled: !superAdmin,
                  },
               ],
            },
            {
               text: 'Statistics',
               classes: this.sysBtn,
               divider: true,
               submenus: [
                  {
                     text: 'Postgres',
                     classes: this.superBtn,
                     disabled: !superAdmin,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.statistic,
                        paths.sysadmins.statistics.postgres,
                     ],
                  },
                  {
                     text: 'Transaction numbers',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.statistic,
                        paths.sysadmins.statistics.transactionNumbers,
                     ],
                  },
                  {
                     text: 'Record numbers',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.statistic,
                        paths.sysadmins.statistics.recordNumbers,
                     ],
                  },
                  {
                     text: 'Statuses',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.statistic,
                        paths.sysadmins.statistics.statuses,
                     ],
                  },
                  {
                     text: 'Alerts',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.statistic,
                        paths.sysadmins.statistics.alerts,
                     ],
                  },
                  {
                     text: 'Runtime',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.statistic,
                        paths.sysadmins.statistics.runtime,
                     ],
                  },
                  {
                     text: 'NMVS Error codes',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.statistic,
                        paths.sysadmins.statistics.nmvs,
                     ],
                  },
                  {
                     text: 'Batch number',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.statistic,
                        paths.sysadmins.statistics.batchNumber,
                     ],
                  },
                  {
                     text: 'Record processing times',
                     classes: this.sysBtn,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.statistic,
                        paths.sysadmins.statistics.recordProcTimes,
                     ],
                  },
               ],
            },
            {
               text: 'Histories',
               classes: this.sysBtn,
               divider: true,
               submenus: [
                  {
                     text: 'Users',
                     link: [paths.history, paths.histories.users],
                  },
                  {
                     text: 'User rights',
                     link: [paths.history, paths.histories.userRights],
                  },
                  {
                     text: 'Managers',
                     link: [paths.history, paths.histories.managers],
                  },
               ],
            },
            {
               text: 'Search',
               classes: this.sysBtn,
               divider: true,
               submenus: [
                  {
                     text: 'Datamatrix',
                     classes: this.sysBtn,
                     divider: true,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.search,
                        paths.sysadmins.searches.datamatrix,
                     ],
                  },
                  {
                     text: 'Contacts',
                     classes: this.sysBtn,
                     divider: true,
                     link: [
                        paths.sysadmin,
                        paths.sysadmins.search,
                        paths.sysadmins.searches.contacts,
                     ],
                  },
                  {
                     text: 'Data queries',
                     classes: this.sysBtn,
                     divider: true,
                     submenus: [
                        {
                           text: 'Sessions',
                           classes: this.sysBtn,
                           link: [
                              paths.sysadmin,
                              paths.sysadmins.search,
                              paths.sysadmins.searches.sessions,
                           ],
                        },
                        {
                           text: 'Tasks',
                           classes: this.sysBtn,
                           link: [
                              paths.sysadmin,
                              paths.sysadmins.search,
                              paths.sysadmins.searches.tasks,
                           ],
                        },
                        {
                           text: 'Records',
                           classes: this.sysBtn,
                           link: [
                              paths.sysadmin,
                              paths.sysadmins.search,
                              paths.sysadmins.searches.recordsData,
                           ],
                        },
                        {
                           text: 'Transactions',
                           classes: this.sysBtn,
                           link: [
                              paths.sysadmin,
                              paths.sysadmins.search,
                              paths.sysadmins.searches.transactions,
                           ],
                        },
                        {
                           text: 'Alerts',
                           classes: this.sysBtn,
                           link: [
                              paths.sysadmin,
                              paths.sysadmins.search,
                              paths.sysadmins.searches.alerts,
                           ],
                        },
                     ],
                  },
                  {
                     text: 'Master files',
                     classes: this.sysBtn,
                     divider: true,
                     submenus: [
                        {
                           text: 'Divisions',
                           classes: this.sysBtn,
                           link: [
                              paths.sysadmin,
                              paths.sysadmins.search,
                              paths.sysadmins.searches.divisions,
                           ],
                        },
                        {
                           text: 'Certificates',
                           classes: this.sysBtn,
                           link: [
                              paths.sysadmin,
                              paths.sysadmins.search,
                              paths.sysadmins.searches.certificates,
                           ],
                        },
                        {
                           text: 'Users',
                           classes: this.sysBtn,
                           link: [
                              paths.sysadmin,
                              paths.sysadmins.search,
                              paths.sysadmins.searches.users,
                           ],
                        },
                        {
                           text: 'Task names',
                           classes: this.sysBtn,
                           link: [
                              paths.sysadmin,
                              paths.sysadmins.search,
                              paths.sysadmins.searches.tasknames,
                           ],
                        },
                     ],
                  },
                  {
                     text: 'Maintenance',
                     classes: this.sysBtn,
                     divider: false,
                     submenus: [
                        {
                           text: 'User divisions',
                           classes: this.sysBtn,
                           link: [
                              paths.sysadmin,
                              paths.sysadmins.search,
                              paths.sysadmins.searches.userDivisions,
                           ],
                        },
                        {
                           text: 'User rights',
                           classes: this.sysBtn,
                           link: [
                              paths.sysadmin,
                              paths.sysadmins.search,
                              paths.sysadmins.searches.userRights,
                           ],
                        },
                        {
                           text: 'Managers',
                           classes: this.sysBtn,
                           link: [
                              paths.sysadmin,
                              paths.sysadmins.search,
                              paths.sysadmins.searches.managers,
                           ],
                        },
                     ],
                  },
               ],
            },
            {
               text: 'Scheduled maintenance',
               classes: this.sysBtn,
               divider: true,
               link: [paths.scheduledMaintenance],
            },
            {
               text: 'Resend records',
               classes: this.sysBtn,
               divider: false,
               disabled: !superAdmin,
               link: [paths.resendRecords],
            },
         ],
      };
      translateDropdown(dropdown, translate);
      this.navbarHandler.addNav(dropdown);
   }

   navAdmin(arvato: boolean): void {
      const dropdown: IDropdown = {
         text: 'Admin',
         classes: this.btn,
         submenus: [
            {
               text: 'Master files',
               divider: true,
               submenus: [
                  {
                     text: 'Divisions',
                     link: [
                        paths.admin,
                        paths.admins.masterFile,
                        paths.admins.masterFiles.divisions,
                     ],
                  },
                  {
                     text: 'Users',
                     link: [paths.admin, paths.admins.masterFile, paths.admins.masterFiles.users],
                  },
                  {
                     text: 'Terminals',
                     link: [
                        paths.admin,
                        paths.admins.masterFile,
                        paths.admins.masterFiles.terminals,
                     ],
                  },
                  arvato ? {
                     text: 'Certificates',
                     link: [
                        paths.admin,
                        paths.admins.masterFile,
                        paths.admins.masterFiles.certificates,
                     ],
                  } : {
                     text: 'SolidSoft credentials',
                     link: [
                        paths.admin,
                        paths.admins.masterFile,
                        paths.admins.masterFiles.solidsoftCredentials,
                     ],
                  },
                  {
                     text: 'Task names',
                     link: [
                        paths.admin,
                        paths.admins.masterFile,
                        paths.admins.masterFiles.taskNames,
                     ],
                  },
                  {
                     text: 'API keys',
                     classes: this.sysBtn,
                     link: [
                        paths.admin,
                        paths.admins.masterFile,
                        paths.admins.masterFiles.apiKeys,
                     ],
                  },
                  {
                     text: 'IP guard',
                     classes: this.sysBtn,
                     link: [
                        paths.admin,
                        paths.admins.masterFile,
                        paths.admins.masterFiles.ipGuard,
                     ],
                  },
               ],
            },
            {
               text: 'Maintenance',
               divider: true,
               submenus: [
                  {
                     text: 'User divisions',
                     link: [
                        paths.admin,
                        paths.admins.maintenance,
                        paths.admins.maintenances.userDivisions,
                     ],
                  },
                  {
                     text: 'User rights',
                     link: [
                        paths.admin,
                        paths.admins.maintenance,
                        paths.admins.maintenances.userRights,
                     ],
                  },
                  {
                     text: 'Manager settings',
                     link: [
                        paths.admin,
                        paths.admins.maintenance,
                        paths.admins.maintenances.manager,
                     ],
                  },
                  {
                     text: 'Audit log',
                     link: [
                        paths.admin,
                        paths.admins.maintenance,
                        paths.admins.maintenances.auditLog,
                     ],
                  },
               ],
            },
            {
               text: 'Communication',
               submenus: [
                  {
                     text: 'Contacts',
                     link: [
                        paths.admin,
                        paths.admins.communication,
                        paths.admins.communications.contacts,
                     ],
                  },
                  {
                     text: 'News and upgrades',
                     link: [
                        paths.admin,
                        paths.admins.communication,
                        paths.admins.communications.news,
                     ],
                  },
                  {
                     text: 'Helpdesk',
                     link: [
                        paths.admin,
                        paths.admins.communication,
                        paths.admins.communications.helpdesk,
                     ],
                  },
               ],
            },
         ],
      };
      translateDropdown(dropdown, translate);
      this.navbarHandler.addNav(dropdown);
   }

   navMasterFiles(isManager: boolean): void {
      const dropdown: IDropdown = {
         text: 'Master files',
         classes: this.btn,
         submenus: [
            isManager
               ? {
                    text: 'Managed users',
                    link: [paths.masterFile, paths.masterFiles.managedUsers],
                    divider: true,
                 }
               : undefined,
            { text: 'Terminals', link: [paths.masterFile, paths.masterFiles.terminals] },
            { text: 'Products', link: [paths.masterFile, paths.masterFiles.products] },
            { text: 'Wholesalers', link: [paths.masterFile, paths.masterFiles.wholesalers] },
            {
               text: 'Decommission reasons',
               link: [paths.masterFile, paths.masterFiles.decommissionReasons],
            },
            { text: 'NMVS errors', link: [paths.masterFile, paths.masterFiles.nmvsErrors] },
         ],
      };
      translateDropdown(dropdown, translate);
      this.navbarHandler.addNav(dropdown);
   }

   navQueries(): void {
      const dropdown: IDropdown = {
         text: 'Data queries',
         classes: this.btn,
         submenus: [
            { text: 'Sessions', link: [paths.search, paths.searches.sessions] },
            { text: 'Tasks', link: [paths.search, paths.searches.tasks] },
            { text: 'Records', link: [paths.search, paths.searches.recordsData] },
            { text: 'Transactions', link: [paths.search, paths.searches.transactions] },
            { text: 'Alerts', link: [paths.search, paths.searches.alerts] },
         ],
      };
      translateDropdown(dropdown, translate);
      this.navbarHandler.addNav(dropdown);
   }

   navSearch(): void {
      const dropdown: IDropdown = {
         text: 'Search',
         classes: this.btn,
         submenus: [
            { text: 'Datamatrix', link: [paths.search, paths.searches.datamatrix] },
            { text: 'Sessions', link: [paths.search, paths.searches.sessions] },
            { text: 'Tasks', link: [paths.search, paths.searches.tasks] },
            { text: 'Records', link: [paths.search, paths.searches.recordsData] },
            { text: 'Transactions', link: [paths.search, paths.searches.transactions] },
            { text: 'Alerts', link: [paths.search, paths.searches.alerts] },
         ],
      };
      translateDropdown(dropdown, translate);
      this.navbarHandler.addNav(dropdown);
   }

   navStatistics(admin: boolean): void {
      const dropdown: IDropdown = {
         text: 'Statistics',
         classes: this.btn,
         submenus: [
            admin
               ? {
                    text: 'Transaction numbers',
                    link: [paths.statistic, paths.statistics.transactionNumbers],
                 }
               : undefined,
            admin
               ? {
                    text: 'Record numbers',
                    link: [paths.statistic, paths.statistics.recordNumbers],
                 }
               : undefined,
            { text: 'Statuses', link: [paths.statistic, paths.statistics.statuses] },
            { text: 'Alerts', link: [paths.statistic, paths.statistics.alerts] },
            { text: 'Runtime', link: [paths.statistic, paths.statistics.runtime] },
            AppComponent.session.user.provider === ProviderEnum.ARVATO ?
               { text: 'NMVS Error codes', link: [paths.statistic, paths.statistics.nmvs] }
               : undefined,
            { text: 'Batch number', link: [paths.statistic, paths.statistics.batchNumbers] },
         ],
      };
      translateDropdown(dropdown, translate);
      this.navbarHandler.addNav(dropdown);
   }

   navHistories(isAdmin: boolean, isManager: boolean): void {
      if (!isAdmin && !isManager) {
         return;
      }

      const dropdown: IDropdown = {
         text: 'Histories',
         classes: this.btn,
         submenus: [
            {
               text: 'Users',
               link: [paths.history, paths.histories.users],
            },
            {
               text: 'User rights',
               link: [paths.history, paths.histories.userRights],
            },
            isAdmin || !isManager
               ? {
                    text: 'Managers',
                    link: [paths.history, paths.histories.managers],
                 }
               : undefined,
         ],
      };
      translateDropdown(dropdown, translate);
      this.navbarHandler.addNav(dropdown);
   }

   navButtons(language: boolean): void {
      const buttons: IButton[] = [
         {
            icon: new IconClass(Icons.print),
            text: '',
            id: 'generate-lists',
            classes: this.btn,
            tooltip: 'Generate lists',
         },
         language
            ? {
                 icon: new IconClass(Icons.language),
                 text: '',
                 id: 'language',
                 classes: this.btn,
                 tooltip: 'Choose language',
              }
            : undefined,
         {
            icon: new IconClass(Icons.download),
            text: '',
            id: 'download',
            classes: this.btn,
            tooltip: 'Download application',
         },
         {
            icon: new IconClass(Icons.settings),
            text: '',
            id: 'settings',
            classes: this.btn,
            tooltip: 'Settings',
         },
         {
            icon: new IconClass(Icons.signOutAlt),
            text: '',
            id: 'logout',
            classes: this.btn,
            tooltip: 'Logout',
         },
      ];
      translateButtons(buttons, translate);
      this.navbarHandler.setButtons(buttons);
   }
}

export function initNavbar(): Handler {
   const handler = new Handler();
   if (AppComponent.session.rights) {
      const rights = AppComponent.session.rights.find(
         (r) => r.divId === AppComponent.session.user.lastDiv,
      );
      handler.navDivision();
      if (rights.sys) {
         handler.navSysadmin(rights.super);
      }
      if (rights.admin) {
         handler.navAdmin(AppComponent.session.user.provider ===  ProviderEnum.ARVATO);
      }
      handler.navMasterFiles(rights.manager);
      handler.navQueries();
      handler.navStatistics(rights.admin);
      handler.navHistories(rights.admin, rights.manager);
      handler.navSearch();
      handler.navButtons(rights.language);
   }
   return handler;
}
