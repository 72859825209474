import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpService, isResultValid, translate, TranslatePipe } from 'src/app/main/http-service';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import packageInfo from '../../../package.json';
import { AppComponent } from '../app.component';
import { routes } from '../config/routes.config';
import { Icons } from '../handlers/icon.handler';
import { AddInvalidControl, AddInvalidError, InvalidDataType } from '../main/alert/alert.validator';
import { convertDateToLocale } from '../main/table/table.handler';
import { IGetEmail } from '../medtracs-entities/settings/get.email.entity.js';
import { IGetVersions } from '../medtracs-entities/settings/get.versions.entity';

@Component({
   selector: 'app-settings',
   templateUrl: './settings.component.html',
   styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements OnInit {
   icons = Icons;

   passwordMatchError = false;

   passwordCorrectError = false;

   passwordChanged = false;

   hideCardBodyPw = false;

   hideCardBodyEmail = false;

   hideCardBodyVersion = true;

   admin = AppComponent.isRight('admin');

   manager = AppComponent.isRight('manager');

   version = packageInfo.version;

   versions: IGetVersions;

   changePw = new UntypedFormGroup(
      {
         'current-password': new UntypedFormControl({ value: '', disabled: false }, Validators.required),
         pw1: new UntypedFormControl({ value: '', disabled: false }, [
            Validators.required,
            Validators.minLength(8),
         ]),
         pw2: new UntypedFormControl({ value: '', disabled: false }, [
            Validators.required,
            Validators.minLength(8),
         ]),
      },
      [
         (control: UntypedFormGroup): any => {
            const pw1 = control.get('pw1');
            const pw2 = control.get('pw2');
            if (pw1.touched && pw2.touched && pw1.value !== pw2.value) {
               return { newPasswordsDoNotMatch: true };
            }
            if ((pw1.errors && pw1.errors.minlength) || (pw2.errors && pw2.errors.minlength)) {
               return { minlength: true };
            }
            if ((pw1.errors && pw1.errors.pattern) || (pw2.errors && pw2.errors.pattern)) {
               return { pattern: true };
            }
            const current = control.get('current-password');
            if (current.touched && pw1.touched && current.value === pw1.value) {
               return { cannotBeSameAsCurrent: true };
            }
            return null;
         },
      ],
   );

   emailSettings = new UntypedFormGroup({
      email: new UntypedFormControl({ value: '', disabled: false }, Validators.email),
      validity: new UntypedFormControl({ value: '', disabled: true }),
      confirmed: new UntypedFormControl({ value: false, disabled: true }),
      login: new UntypedFormControl({ value: true, disabled: true }),
      block: new UntypedFormControl({ value: true, disabled: true }),
      news: new UntypedFormControl({ value: true, disabled: true }),
      alert: new UntypedFormControl({ value: true, disabled: true }),
   });

   clientInvalidData: InvalidDataType = [];

   private systemService: HttpService;

   private settingsService: HttpService;

   constructor(private http: HttpClient) {
      this.systemService = new HttpService(http, routes.system);
      this.settingsService = new HttpService(http, routes.settings);
      const minLength = translate('Password length must be at least 8 characters!');
      const pattern = translate(
         'Password must contain at least 1 special character (of "!@#$%^&"), 1 number, 1 uppercase and 1 lowercase letter of the English alphabet.',
      );
      AddInvalidControl(this.clientInvalidData, this.changePw, ['minlength', minLength]);
      AddInvalidError(this.clientInvalidData, this.changePw, ['pattern', pattern]);
      AddInvalidError(this.clientInvalidData, this.changePw, [
         'newPasswordsDoNotMatch',
         translate('New passwords do not match.'),
      ]);
      AddInvalidError(this.clientInvalidData, this.changePw, [
         'cannotBeSameAsCurrent',
         translate('New password cannot be the same as current password.'),
      ]);
   }

   ngOnInit(): void {
      this.get();
   }

   emailChange(): void {
      if (this.emailSettings.get('email').valid && this.emailSettings.get('email').value) {
         this.emailSettings.get('login').enable();
         this.emailSettings.get('block').enable();
         this.emailSettings.get('news').enable();
         this.emailSettings.get('alert').enable();
      } else {
         this.emailSettings.get('login').disable();
         this.emailSettings.get('block').disable();
         this.emailSettings.get('news').disable();
         this.emailSettings.get('alert').disable();
      }
   }

   get(): void {
      this.settingsService.post({}, (res: HttpResponse<{ email: IGetEmail } & IGetVersions>) => {
         if (isResultValid(res)) {
            this.emailSettings.get('email').setValue(res.body.email.email);
            this.emailSettings.get('confirmed').setValue(res.body.email.emailCfm);
            if (res.body.email.emailCfm) {
               convertDateToLocale([res.body.email], ['emailValid'], TranslatePipe.getLocale());
               this.emailSettings.get('validity').setValue(res.body.email.emailValid);
            } else {
               this.emailSettings.get('validity').reset();
            }
            this.emailSettings.get('login').setValue(res.body.email.loginEmail);
            if (this.admin || this.manager) {
               this.emailSettings.get('block').setValue(res.body.email.blockEmail);
            }
            if (this.admin) {
               this.emailSettings.get('news').setValue(res.body.email.newsEmail);
            }
            if (this.admin || this.manager) {
               this.emailSettings.get('alert').setValue(res.body.email.alertEmail);
            }
            this.emailChange();
            this.versions = res.body;
         }
      });
   }

   onSubmit(): void {
      if (this.changePw.get('pw1').value === this.changePw.get('pw2').value) {
         this.passwordMatchError = false;
         this.passwordCorrectError = false;
         this.passwordChanged = false;
         this.systemService.patch<any>(
            'password',
            {
               password: this.changePw.get('current-password').value,
               newPassword: this.changePw.get('pw1').value,
            },
            (res) => {
               if (isResultValid(res)) {
                  this.passwordChanged = true;
               }
               this.changePw.reset();
            },
         );
      } else {
         this.passwordMatchError = true;
      }
   }

   onSubmitEmail(): void {
      this.settingsService.put(
         // 'email',
         {
            email: this.emailSettings.get('email').value || '',
            loginEmail: this.emailSettings.get('login').value,
            blockEmail: this.emailSettings.get('block').value,
            newsEmail: this.emailSettings.get('news').value,
            alertEmail: this.emailSettings.get('alert').value,
         },
         (res) => {
            this.emailSettings.reset();
            this.get();
         },
      );
   }

   changeHidePw(): void {
      this.hideCardBodyPw = !this.hideCardBodyPw;
   }

   changeHideEmail(): void {
      this.hideCardBodyEmail = !this.hideCardBodyEmail;
      if (!this.hideCardBodyEmail) {
         this.get();
      }
   }
}
