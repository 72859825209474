<label for="app-option">{{ header }}</label>
<select class="custom-select" (change)="onChange(control.value)" [formControl]="control" #select>
   <option *ngIf="!loaded; else choose" value="-1" selected>...</option>
   <ng-template #choose>
      <option value="-1" hidden>{{ chooseStr ? chooseStr : 'Válasszon' }}</option>
   </ng-template>
   <option
      *ngFor="let option of options"
      [ngClass]="itemActiveField === undefined ? '' : option[itemActiveField] === undefined || !!option[itemActiveField] ? '' : 'disabled'"
      value="{{ option._id ? option._id : options.indexOf(option) }}"
   >
      {{ getFieldValue(option, field) }}
   </option>
</select>
