<lib-select
   [elemList]="elemList"
   [options]="users"
   [field]="'name'"
   [load]="loadUserObs"
   [unload]="unloadUserObs"
   [myName]="'userTmp'"
   [myGroup]="form"
   (changed)="onChange($event)"
   [chooseStr]="chooseString"
>
</lib-select>
