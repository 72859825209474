import { ParamMap } from '@angular/router';
import { IObject } from './observables';
import { IType } from './type';

export class IQuery {
   private value: IType[];

   constructor(value: IType[]) {
      this.value = value.filter((type) => !!type);
   }

   addFilter(filter: IType): IQuery {
      this.value.push(filter);
      return this;
   }

   addQuery(query: IQuery | Record<string, unknown>): IQuery {
      if (query instanceof IQuery) {
         this.value = this.value.concat(query.getValue());
      } else {
         this.value = this.value.concat(
            Object.keys(query).map((key) => new IType(key, query[key])),
         );
      }
      return this;
   }

   getValue(): IType[] {
      return this.value;
   }

   toString(): string {
      let str = '';
      this.value.forEach((element) => {
         const elem = element.toString();
         str += elem + (elem ? ':' : '');
      });
      return str;
   }

   toObject(): Record<string, unknown> {
      let obj: IObject = {};
      this.value.forEach((element) => {
         obj = Object.assign(obj, element.toObject());
      });
      return obj;
   }
}

export function getParamFilter(params: ParamMap, modifier = 'in'): IQuery {
   const filter: IType[] = [];
   // eslint-disable-next-line no-restricted-syntax
   for (const key of params.keys) {
      const value = params.getAll(key);
      filter.push(
         new IType(key, value.length > 1 ? value : value[0], value.length > 1 ? modifier : ''),
      );
   }
   return new IQuery(filter);
}
